import {useHttp} from "./http.hook";

export const useUsers = () => {
    const {loading, request} = useHttp();

    const getMarketingUsers =async()=>{
        try{
            const data = await request("/api/user/marketingUsers","GET",null,true);
            return data;
        }catch (e) {

        }
    }

    const getDirectorChildManagers = async() =>
    {
        try{
            const data = await request("/api/user/directorChildManagers","GET",null,true);
            return data;
        }catch (e) {

        }
    }

    const getManagerChildUsers = async(managerId,includeParent) =>
    {
        try{
            const data = await request("/api/user/managerChildUsers","POST", {parentId:managerId,includeParent:includeParent},true);
            return data;
        }catch (e) {

        }
    }

    const getUserByEmail =async(email)=>{
        try{
            const data = await request("/api/user/userByEmail","POST", {email:email},true);
            return data;
        }catch (e) {

        }
    }

    return  {getMarketingUsers,getUserByEmail,getDirectorChildManagers,getManagerChildUsers}
}