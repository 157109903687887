import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useHttp} from "../hooks/http.hook";
import {Loader} from "../components/Loader";
import {  useNavigate} from "react-router-dom";
import {useMessage} from "../hooks/message.hook";


export const PasswordRecoveryPage = () =>{
	const navigate = useNavigate();

	const message = useMessage();
	const {request,loading} = useHttp();

	const [newPassword, setNewPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");

	let hash = useParams().hash;

	const savePasswordHandler = async (e) =>{
		e.preventDefault();
		console.log(newPassword);
		if(newPassword.length < 6)
		{
			message("Password minimum 6 symbols");
			return null;
		}

		if(newPassword !== repeatPassword)
		{
			message("Passwords don't match");
			return null;
		}

		try{
			const fetched = await request(`/api/auth/passwordHashVerify/${hash}`,"POST",{newPassword:newPassword})

			message(fetched.message);

			navigate("/");

		}catch (e) {
			message(e.message);
		}
	}

	const newPasswordChangeHandler = event =>
	{
		setNewPassword(event.target.value);
	}
	const repeatPasswordChangeHandler = event =>
	{
		setRepeatPassword(event.target.value);
	}

	if(loading)
	{
		return <Loader />
	}

	return(

		<>
			<div className="row ">

				<div className="col m6 offset-m3 s12 ">
					<form className="card grey darken-3">

						<div className="card-content white-text login-container">

							<h4>Reset Password</h4>
							<div>
								<div className="input-field">
									<input
										placeholder="New Password"
										id="password"
										type="password"
										name="password"
										className="yellow-input"
										value={newPassword}
										onChange={newPasswordChangeHandler}
										disabled={loading}/>
								</div>
								<div className="input-field">
									<input
										placeholder="Repeat your password"
										id="password"
										type="password"
										name="repeatPassword"
										className="yellow-input"
										value={repeatPassword}
										onChange={repeatPasswordChangeHandler}
										disabled={loading}/>
								</div>
							</div>
						</div>
						<div className="card-action">
							<button className="btn green darken-1" style={{marginRight:10}}
									onClick={savePasswordHandler}>Save Password</button>

						</div>
					</form>
				</div>

			</div>

		</>
	)
}