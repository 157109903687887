import React, {useState} from 'react';
import {useHttp} from "../hooks/http.hook";
import {useCallback, useEffect, useContext} from "react";
import {Loader} from "../components/Loader";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";
import {useMcSettings} from "../hooks/mcSettings.hook";
import 'materialize-css';

export const MCPage = () =>{
    const [isUpdated,setIsUpdated] = useState(false);
    const [mcSettings,setMcSettings] = useState([]);
    const {userType,userId} = useContext(AuthContext);
    const [parentMcSettings,setParentMcSettings] = useState([]);
    const [freeLines, setFreeLines] = useState([1,2,3,4,5,6]);
    const [newMCSettings,setNewMCSettings] = useState({
        name:"",
        parentMc:-1,
        number:0,
        lines:[],
        emailContent:"",
        emailSubject:""
    });
    const {getMcSettings,getParentMcSettings} = useMcSettings();
    const [mcToBeDeleted, setMcToBeDeleted] = useState({entryId:null,entryName:""});

    const {loading, request} = useHttp();
    const message = useMessage();

    const defaultEmailSubject = () =>{
        return "&Origin& -> &Dest& | &Miles& | &LoadDay& | &TruckType&";
    }

    const fetchSettings = useCallback(async ()=>{
        try{

            const data = await getMcSettings();

            if(userType ==="agent")
            {
                const parentData = await getParentMcSettings();
                setParentMcSettings(parentData);
            }

            let _freeLines = [1,2,3,4,5,6];
            for(let i=0;i<data.length;i++)
            {
                let lines = data[i].lines;
                for (let j=0;j<lines.length;j++)
                {
                    if(_freeLines.indexOf(lines[j]) !== -1)
                    {
                        _freeLines.splice(_freeLines.indexOf(lines[j]),1);
                    }

                }
            }
            setFreeLines(_freeLines);
            setIsUpdated(false);
            setNewMCSettings({
                name:"",
                parentMc:-1,
                number:0,
                lines:[],
                emailContent:"Hello, give me the details, please",
                emailSubject:defaultEmailSubject()
            })
            setMcSettings(data);
        }catch (e) {

        }
    },[request,mcSettings,freeLines,isUpdated]);

    const saveSettingsClickHandler = async e =>{
        e.preventDefault();

        await SaveSettings();
    }

    const SaveSettings = async () =>{
        try {

            const data = await  request('/api/mcSettings/update/details', 'POST', {mcSettings:mcSettings},true);

            message(data.message);

            await fetchSettings();
        }catch (e) {
            message(e.message);
        }
    }

    const DeleteRowOpenPopupHandler = (entryId,entryName)=>{

        setMcToBeDeleted({
            entryId: entryId,
            entryName:entryName
        })

        let modal = window.document.getElementById('delete_popup');
        window.M.Modal.init(modal,{});
        let instance = window.M.Modal.getInstance(modal);
        instance.open();
    }

    const DeleteFilterAgreeHandler = async  (e)=>{
        e.preventDefault();

        await deleteMCHandler(mcToBeDeleted.entryId)
    }


    const deleteMCHandler = async (id) =>{

        try {

            const data = await  request('/api/mcSettings/', 'DELETE', {settingID:id},true);

            message(data.message);

            await fetchSettings();
        }catch (e) {
            message(e.message);
        }

    }

    const toggleVisibilityHandler = async e =>{
        e.preventDefault();

        const id = e.target.dataset.parent;

        try {

            const data = await  request('/api/mcSettings/update/toggleVisibility', 'POST', {settingID:id},true);

            message(data.message);

            await fetchSettings();
        }catch (e) {
            message(e.message);
        }
    }

    useEffect(()=>{
        fetchSettings();
        window.M.AutoInit();
    },[]);


    const newMCNameHandler = e => {setNewMCSettings({...newMCSettings, name:e.target.value})}
    const newMCParentChangeHandler = e => {setNewMCSettings({...newMCSettings, parentMc:e.target.value})}
    const newMCEmailContentHandler = e => {setNewMCSettings({...newMCSettings, emailContent:e.target.value})}
    const newMCNumberHandler = e => {setNewMCSettings({...newMCSettings, number:e.target.value})}

    const lineClickHandler = e =>
    {
        e.preventDefault();

        let lineNumber = parseInt(e.target.dataset.number);
        let newArray = freeLines.slice();

        if(newArray.indexOf(lineNumber) === -1)
        {
             newArray.push(lineNumber);
        }
        else
        {
            newArray.splice(newArray.indexOf(lineNumber), 1);
        }

        const parent = e.target.dataset.parent;
        let prevLineNumber;
        if(parent === "new")
        {
            let linesArray = newMCSettings.lines.slice();

            if(linesArray.indexOf(lineNumber) === -1)
            {
                prevLineNumber = linesArray[0];
                linesArray = [lineNumber]
                // linesArray.push(lineNumber);
            }
            else
            {
                linesArray.splice(linesArray.indexOf(lineNumber), 1);
            }

            setNewMCSettings({...newMCSettings, lines:linesArray})
        }
        else
        {
            const settingObj =  mcSettings.find(s => s._id === parent);

            let linesArray = settingObj.lines.slice();

            if(linesArray.indexOf(lineNumber) === -1)
            {
                prevLineNumber = linesArray[0];
                linesArray = [lineNumber]
                // linesArray.push(lineNumber);
            }
            else
            {
                linesArray.splice(linesArray.indexOf(lineNumber), 1);
            }

            settingObj.lines =linesArray;

            setIsUpdated(true);


            setMcSettings(mcSettings);

        }
        newArray.push(prevLineNumber);
        setFreeLines(newArray);


    }

    const mcNameChangeHandler = e => {
        e.preventDefault();

        let newSet = [...mcSettings]

        const parent = e.target.dataset.parent;

        const settingObj =  newSet.find(s => s._id === parent);

        settingObj.name = e.target.value;

        setMcSettings(newSet);

        setIsUpdated(true);

    }

    const mcEmailContentChangeHandler = e =>
    {
        e.preventDefault();

        let newSet = [...mcSettings]

        const parent = e.target.dataset.parent;

        const settingObj =  newSet.find(s => s._id === parent);

        settingObj.emailContent = e.target.value;

        setMcSettings(newSet);

        setIsUpdated(true);
    }

    const mcEmailSubjectChangeHandler = e =>
    {
        e.preventDefault();

        const parent = e.target.dataset.parent;


        if(parent === "new")
        {
            setNewMCSettings({...newMCSettings, emailSubject:e.target.value});
        }
        else
        {
            let newSet = [...mcSettings]

            const settingObj =  newSet.find(s => s._id === parent);

            settingObj.emailSubject = e.target.value;

            setMcSettings(newSet);

            setIsUpdated(true);
        }
    }

    const addMCHandler = async e =>{
        e.preventDefault();

        if(userType === "manager" && newMCSettings.name === "")
        {
            message("Name is empty");
            return;
        }else if(userType === "agent" && newMCSettings.parentMc === "-1")
        {
            message("Select MC");
            return;
        }

        //
        // if(newMCSettings.number === 0 || newMCSettings.number === "")
        // {
        //     message("MC Number is Empty");
        //     return;
        // }

        if(newMCSettings.lines.length === 0)
        {
            message("Select line for new MC");
            return;
        }

        try {

            const data = await  request(`/api/mcSettings/add/`, 'POST',
                {
                        name:newMCSettings.name,
                        parentMc:newMCSettings.parentMc,
                        // number:newMCSettings.number,
                        lines:newMCSettings.lines,
                        emailContent:newMCSettings.emailContent,
                        emailSubject:newMCSettings.emailSubject
                     },true);
            message(data.message);

            setNewMCSettings({
                name:"",
                parentMc: "-1",
                number:0,
                lines:[],
                emailContent: "Hello, give me the details, please",
                emailSubject:defaultEmailSubject()
            })

            await fetchSettings();
        }
        catch (e) {

        }
    }

    const currentMCRows = () =>{

        console.log(mcSettings);

        return (
            <>
                {mcSettings.length>0 ? mcSettings.map((setting,index) =>{
                    return (
                        <tr key={setting._id}>
                            <td>
                                <label>
                                    <input type="checkbox" data-parent={setting._id}  className="filled-in" checked={setting.isVisible?"checked":""}  onChange={toggleVisibilityHandler}/>
                                    <span>{setting.isVisible?"Visible":"Hidden"}</span>
                                </label>



                            </td>
                            <td>
                                {setting.parentMc ? (
                                   <input type="text" value={setting.parentMc.name} disabled="disabled"/>
                                ):(
                                    <input type="text" value={setting.name} data-parent={setting._id} onChange={mcNameChangeHandler} />
                                )}
                            </td>
                            {/*<td>*/}
                            {/*    <input type="text" value={setting.mcNumber} data-parent={setting._id} onChange={mcNumberChangeHandler} />*/}
                            {/*</td>*/}
                            <td>{printLines(setting._id)}</td>
                            <td>{printEmailSubject(setting._id)}</td>
                            <td>
                                <textarea className="EmailContentTextArea" value={setting.emailContent} data-parent={setting._id} onChange={mcEmailContentChangeHandler} />
                            </td>



                            <td>
                                <button className="btn waves-effect waves-light  red darken-3" style={{marginLeft:'1rem'}}
                                        onClick={
                                                () =>
                                                {
                                                    let mcName;
                                                    if(setting.parentMc)
                                                    {
                                                        mcName = setting.parentMc.name;
                                                    }
                                                    else
                                                    {
                                                        mcName = setting.name;
                                                    }
                                                    DeleteRowOpenPopupHandler(setting._id, mcName)
                                                }
                                                }
                                >
                                    <i className="material-icons">delete</i>
                                </button>

                            </td>
                        </tr>
                    )
                }): <></>
                }
            </>

        )
    }


    const printLines = (objectId) =>{

        let selectedLines = [];
        if(objectId === "new")
        {
            selectedLines = newMCSettings.lines;
        }
        else
        {
            selectedLines = mcSettings.find(s => s._id === objectId).lines
        }

        return (
            <>
                <div>
                    Selected Line(s):
                    {
                        selectedLines.map((line)=>{
                            return (" "+line)
                        })
                    }
                </div>
                <button className={`btn btn-small ${selectedLines.indexOf(1)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(1)===-1 && selectedLines.indexOf(1)===-1} data-number={1} data-parent={objectId} onClick={lineClickHandler} >1</button>
                <button className={`btn btn-small ${selectedLines.indexOf(2)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(2)===-1 && selectedLines.indexOf(2)===-1} data-number={2} data-parent={objectId}  onClick={lineClickHandler} >2</button>
                <button className={`btn btn-small ${selectedLines.indexOf(3)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(3)===-1 && selectedLines.indexOf(3)===-1} data-number={3} data-parent={objectId} onClick={lineClickHandler} >3</button>
                <button className={`btn btn-small ${selectedLines.indexOf(4)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(4)===-1 && selectedLines.indexOf(4)===-1} data-number={4} data-parent={objectId} onClick={lineClickHandler} >4</button>
                <button className={`btn btn-small ${selectedLines.indexOf(5)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(5)===-1 && selectedLines.indexOf(5)===-1} data-number={5} data-parent={objectId} onClick={lineClickHandler} >5</button>
                <button className={`btn btn-small ${selectedLines.indexOf(6)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(6)===-1 && selectedLines.indexOf(6)===-1} data-number={6} data-parent={objectId} onClick={lineClickHandler} >6</button>
                {/*<br />*/}
                {/*<button className={`btn btn-small ${selectedLines.indexOf(7)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(7)===-1 && selectedLines.indexOf(7)===-1} data-number={7} data-parent={objectId} onClick={lineClickHandler} >7</button>*/}
                {/*<button className={`btn btn-small ${selectedLines.indexOf(8)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(8)===-1 && selectedLines.indexOf(8)===-1} data-number={8} data-parent={objectId} onClick={lineClickHandler} >8</button>*/}
                {/*<button className={`btn btn-small ${selectedLines.indexOf(9)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(9)===-1 && selectedLines.indexOf(9)===-1} data-number={9} data-parent={objectId} onClick={lineClickHandler} >9</button>*/}
                {/*<button className={`btn btn-small ${selectedLines.indexOf(10)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(10)===-1 && selectedLines.indexOf(10)===-1} data-number={10} data-parent={objectId} onClick={lineClickHandler} >10</button>*/}
                {/*<button className={`btn btn-small ${selectedLines.indexOf(11)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(11)===-1 && selectedLines.indexOf(11)===-1} data-number={11} data-parent={objectId} onClick={lineClickHandler} >11</button>*/}
                {/*<button className={`btn btn-small ${selectedLines.indexOf(12)===-1?"blue-grey":"green"} darken-1 linebtn`} disabled={freeLines.indexOf(12)===-1 && selectedLines.indexOf(12)===-1} data-number={12} data-parent={objectId} onClick={lineClickHandler} >12</button>*/}
            </>
        )
    }

    const printEmailSubject = (objectId) => {

        let val = {};
        if(objectId === "new")
        {
            val = newMCSettings.emailSubject;
        }
        else
        {
            val = mcSettings.find(s => s._id === objectId).emailSubject
        }

        return (
            <>
               <select className={"browser-default"}  value={val} data-parent={objectId} onChange={mcEmailSubjectChangeHandler}>
                   <option value={defaultEmailSubject()}>Origin -> Destination | Miles | LoadDay | TruckType</option>
                   <option value="&Origin& -> &Dest& , &LoadDay& , &TruckType&">Origin -> Destination , LoadDay , TruckType</option>
                   <option value="&Origin& (&Miles&) &Dest& &LoadDay& , &TruckType&">Origin (Miles) Dest LoadDay , TruckType</option>
                   <option value="&Origin& to &Dest&">Origin to Dest</option>
               </select>
            </>
        )
    }


    const printAdMCRow = () =>{

        return (
            <>
                <tr>
                    <td>
                        {userType==="manager"?(
                            <>
                                <label>New MC Name</label>
                                <input type="text" value={newMCSettings.name} onChange={newMCNameHandler} />
                            </>

                            ):(

                            <>
                                <label>Choose MC</label>
                                <select className="browser-default" style={{width:"281px"}} value={newMCSettings.parentMc} onChange={newMCParentChangeHandler}>
                                    <option value="-1">Select MC</option>
                                    {parentMcSettings.map(function(parentMc, i){
                                        return (
                                            <option value={parentMc._id}>{parentMc.name}</option>
                                        );
                                    })}
                                </select>

                            </>
                        )}

                    </td>
                    {/*<td>*/}
                    {/*    <label>New MC Number</label>*/}
                    {/*    <input type="number" value={newMCSettings.number} onChange={newMCNumberHandler}/>*/}
                    {/*</td>*/}
                    <td>
                        <div><label>New MC Lines</label></div>
                        {printLines("new")}
                    </td>
                    <td>
                        {printEmailSubject("new")}
                    </td>
                    <td>
                        <textarea className="EmailContentTextArea" value={newMCSettings.emailContent} onChange={newMCEmailContentHandler}  />
                    </td>

                    <td>
                        <button className="btn waves-effect waves-light orange darken-3" onClick={addMCHandler}>Add
                            <i className="material-icons right">add</i>
                        </button>
                    </td>

                </tr>
            </>
        )
    }

    return(
        <>
            {(!loading) ?
                <>
                    <div className="row" >

                        <div className={"card"} style={{padding:"15px"}}>
                            <h5>Add New MC</h5>
                            <table>
                                <thead>
                                <tr>
                                    <th>MC {userType==="manager"?"Name":""}</th>
                                    {/*<th>MC Number</th>*/}
                                    <th>Phone Line</th>
                                    <th>Email Subject</th>
                                    <th>Email Content</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {printAdMCRow()}
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <h5>My MC List</h5>
                            {isUpdated ?
                                <button onClick={saveSettingsClickHandler} className="btn waves-effect waves-light yellow darken-4" style={{marginTop:"2rem"}}>
                                    Save Settings
                                </button>
                            :null}
                            <table>
                                <thead>
                                <tr>
                                    <th>Show in DAT</th>
                                    <th>MC Name</th>
                                    {/*<th>MC Number</th>*/}
                                    <th>Phone Line</th>
                                    <th>Email Subject</th>
                                    <th>Email Content</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {currentMCRows()}
                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div id="delete_popup" className="modal small-modal">
                        <div className="modal-content">
                            <h5>Delete Confirm</h5>
                            <span>Are you sure you want to delete {mcToBeDeleted.entryName}?</span>
                            <br/>
                            {userType === "manager"? (
                                <span>{mcToBeDeleted.entryName} will be deleted from all agents.</span>
                            ):""}

                        </div>
                        <div className="modal-footer">
                            <div className="row">
                                <div className="col s12"  style={{marginBottom:10}}>
                                    <button className="btn red modal-close" onClick={DeleteFilterAgreeHandler}>Delete</button>
                                    <button className="btn grey modal-close"  style={{marginLeft:10}}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>



             : <Loader />}

            <a className="right" style={{marginTop:10}} href="https://sudofreight.com/add-update-mc/" target="_blank">Help: How to create lines</a>

        </>
    )
}