import React, {useState} from 'react';
import {useHttp} from "../hooks/http.hook";
import {useCallback, useContext, useEffect} from "react";
import {AuthContext} from "../context/auth.context";
import {Loader} from "../components/Loader";
import {FiltersList} from "../components/FiltersList";
import {useMessage} from "../hooks/message.hook";
import {useFilterTypes} from "../hooks/filterTypes.hook";
import {useMcSettings} from "../hooks/mcSettings.hook";
import {useParams} from 'react-router-dom';
import {  useNavigate} from "react-router-dom";

import Input from 'react-phone-number-input/input'



import 'materialize-css';

export const BrokerFilterPage = () =>{
    const [filters, setFilters] = useState(null);
    const [filterNames, setFilterNames] = useState([]);
    const [disabledFilters, setDisabledFilters] = useState([]);
    const [filterTypes, setFilterTypes] = useState([]);
    const [mcSettingsReceived,setMcSettingsReceived] = useState(false);
    const [mcSettings, setMcSettings] = useState([]);
    const [users, setUsers] = useState([]);
    const {loading, request} = useHttp();
    const {userType,userId,userName,userEmail} = useContext(AuthContext);
    const message = useMessage();
    const {getFilters} = useFilterTypes();
    const [newContactValue,setNewContactValue] = useState("");

    const [testName,setTestName] = useState("Landstar Ranger Inc");
    const [testContact,setTestContact] = useState("(602) 643-1342");

    const [minifiedFitlers, setMinifiedFilters] = useState(null);

    const {getMcSettings} = useMcSettings();
    const [form, setform] = useState({
        name:"",
        type:"-1",
        comment:"",
        onlyCreator:false,
        allMC:true,
        mcList:[],
        usersList:[],
        onlyOnContacts:true,
        contacts:[],
        newUsers:true,
        createFormMode:true,
        updateFilterPrevName:"",
        updateFilterId:"",
        isVisible:false,
        isVerified:false,
        canEdit:true
    })

    const [searchForm,setSearchForm] = useState({
        name:"",
        onlyCreator:true,
        teamWithMe:true,
        teamWithoutMe:true,
        type:"-1",
        onlyContacts:false,
        creator:null,
        allMC:true,
        mcList:[]
    })


    let queryParams = useParams();
    const navigate = useNavigate();


    const fetchFilters = useCallback(async ()=>{
        try{
            const data = await request("/api/broker","POST",searchForm,true);
            setFilters(data);

            const minifiedData = await request("/api/broker/minified","GET",null,true);

            setMinifiedFilters(minifiedData);

            const filterNamesFetch = await request("/api/broker/allNames","GET",null,true);
            setFilterNames(filterNamesFetch);


        }catch (e) {

        }
    },[request,searchForm]);


    const fetchFilterTypes = useCallback(async ()=>{
        try{
            const data = await getFilters();
            setFilterTypes(data);
        }catch (e) {

        }
    },[request]);

    const fetchMCSettings = async ()=>{
        try{
            const data = await getMcSettings();
            setMcSettings(data);
            setMcSettingsReceived(true);
            return data;
        }catch (e) {

        }
    };

    const fetchUsers = async ()=>{
        try{

            const fetchedUsers = await request("/api/user/allTeam","GET",null,true);
            setUsers(fetchedUsers);

            return fetchedUsers;

        }catch (e) {

        }
    }

    const listChangedCallback = () =>{
        fetchFilters();
    }

    useEffect(()=>{
        pageLoaded();
    },[]);

    const pageLoaded = async ()=>{
        window.M.AutoInit();
        window.M.updateTextFields();

        let mcSettings = await fetchMCSettings();

        let users = [];
        if(userType === "manager") users = await fetchUsers();

        if(queryParams.action)
        {
            ProcessParams(queryParams,users,mcSettings);
        }

        await fetchFilterTypes();
        await fetchFilters();
    }

    useEffect( ()=>{
        let elem =document.getElementById("s_companyName");
        let autocompleteInstance = window.M.Autocomplete.getInstance(elem);

        let names = {};
        for(let i=0;i<filterNames.length;i++)
        {
            names[filterNames[i].name] = null;
        }

        autocompleteInstance.updateData(names);

    },[filterNames])

    useEffect( ()=>{
        window.M.FormSelect.init(document.querySelectorAll('.mc_select'));
    },[mcSettings])

    //
    // useEffect(()=>{
    //
    //
    //
    // },[,filters, filterTypes,loading]);

    const ProcessParams =(queryParams, users,mcSettings)=>{

        if(mcSettings.length === 0) return;

        if(queryParams.action === "create")
        {
            console.log("create filter");
            AddOrEditFilterClickHandler();

            let selectedUsers = [];
            for(let i=0;i<users.length;i++)
            {
                selectedUsers.push(users[i]._id);
            }

            if(selectedUsers.indexOf(userId) === -1)
            {
                selectedUsers.push(userId);
            }

            let selectedMC = [];
            for(let i=0;i<mcSettings.length;i++)
            {
                let mcID = mcSettings[i]._id;
                if( mcSettings[i].parentMc){
                    mcID = mcSettings[i].parentMc._id;
                }
                selectedMC.push(mcID);
            }
            console.log(selectedMC);


            setform({...form,name:queryParams.name,contacts:[cleanContact(queryParams.contact)],usersList: selectedUsers,mcList:selectedMC})
        }
        else if(queryParams.action === "search" && queryParams.name)
        {
            setSearchForm({...searchForm,name: queryParams.name});
            searchForm.name = queryParams.name;
            document.getElementById("s_companyName").value = queryParams.name;
        }

    }

    const cleanContact = (contact)=>{
        contact = contact.trim();
        if(contact[0] === "(")
        {
            contact = contact.replaceAll("(","");
            contact = contact.replaceAll(")","");
            contact = contact.replaceAll("-","");
            contact = contact.split(" ")[0] + contact.split(" ")[1]
        }
        return contact;
    }

    const CreateOrUpdateFilterClick =async e=>{
        e.preventDefault();

        form.name = form.name.trim();
        if(form.name === "")
        {
            message("Name is empty");
            return;
        }

        if(form.type === "-1" || form.type === "")
        {
            message("Select the type");
            return;
        }

        if(!form.allMC && form.mcList.length === 0)
        {
            message("Select minimum 1 mc or All MC");
            return;
        }

        let contactsFix = addContactClickHandler();
        if(contactsFix)
        {
            form.contacts = contactsFix;
            setform({...form, contacts:contactsFix});
        }

        if(contactsFix.length === 0 && form.onlyOnContacts)
        {
            form.onlyOnContacts = false;
            setform({...form, onlyOnContacts:false});
        }

        CloseModal();


        try {

            let method = form.createFormMode?"add":"update/details";

            const data = await  request('/api/broker/'+method, 'POST', {...form},true);

            message(data.message);

            await fetchFilters();
        }catch (e) {
            message(e.message);
        }

    }

    const addContactClickHandler = () =>{
        // let val = window.document.getElementById("newContactInput").value;

        let val = newContactValue;
        val = cleanContact(val);


        setNewContactValue("");

        let contacts = [...form.contacts];

        if(val === "") return contacts;

        contacts.push(val);
        setform({...form, contacts:contacts})

        return contacts;
    }

    const deleteContactClickHandler = e=>{
        let index = e.target.dataset.contactindex? parseInt(e.target.dataset.contactindex):parseInt(e.target.parentNode.dataset.contactindex);
        let contacts = [...form.contacts];
        contacts.splice(index,1);
        setform({...form, contacts:contacts})
    }

    const contactValueChangedHandler = e =>{
        let index = parseInt(e.target.dataset.contactindex);
        let contacts = [...form.contacts];
        contacts[index] = e.target.value;

        setform({...form, contacts:contacts})
    }


    const AddOrEditFilterClickHandler = (filter) => {

        if(filter)
        {

            let canEdit;
            if(userType === "manager")
            {
                canEdit = true;
            }
            else
            {
                canEdit = (filter.creator._id.toString() === userId && filter.onlyCreator);
            }

            let mcList = filter.mcList;
            if(filter.allMC)
            {
                for(let i=0;i<mcSettings.length;i++)
                {
                    let mcID = mcSettings[i]._id;
                    if( mcSettings[i].parentMc){
                        mcID = mcSettings[i].parentMc._id;
                    }
                    mcList.push(mcID);
                }
            }

            setform({ ...form,
                updateFilterId:filter._id,
                createFormMode:false,
                updateFilterPrevName: filter.name,
                isVisible:filter.isVisible,
                isVerified:filter.isVerified,
                name:filter.name,
                type:filter.type,
                comment:filter.comment,
                onlyCreator:filter.onlyCreator,
                allMC:filter.allMC,
                mcList:mcList,
                usersList:filter.usersList,
                onlyOnContacts:filter.onlyOnContacts,
                contacts:filter.contacts,
                newUsers:filter.newUsers,
                creator:filter.creator,
                canEdit: canEdit
            });
        }
        else
        {
            let selectedUsers = [];
            for(let i=0;i<users.length;i++)
            {
                selectedUsers.push(users[i]._id);
            }

            if(selectedUsers.indexOf(userId) === -1)
            {
                selectedUsers.push(userId);
            }

            let selectedMC = [];
            for(let i=0;i<mcSettings.length;i++)
            {
                let mcID = mcSettings[i]._id;
                if( mcSettings[i].parentMc){
                    mcID = mcSettings[i].parentMc._id;
                }
                selectedMC.push(mcID);
            }

            setform({ ...form,
                updateFilterId:"",
                createFormMode:true,
                name:"",
                type:"-1",
                comment:"",
                onlyCreator:false,
                allMC:true,
                mcList:selectedMC,
                usersList:selectedUsers,
                onlyOnContacts:true,
                contacts:[],
                newUsers:true,
                canEdit: true
            });


        }

        OpenModal();

    };

    const ShowFilterClickHandler = (filter) =>{


        setform({ ...form,
            updateFilterId:filter._id,
            createFormMode:false,
            updateFilterPrevName: filter.name,
            isVisible:filter.isVisible,
            isVerified:filter.isVerified,
            name:filter.name,
            type:filter.type,
            comment:filter.comment,
            onlyCreator:filter.onlyCreator,
            allMC:filter.allMC,
            mcList:filter.mcList,
            usersList:filter.usersList,
            onlyOnContacts:filter.onlyOnContacts,
            contacts:filter.contacts,
            newUsers:filter.newUsers,
            viewMode: true,
            creator:filter.creator,
            canEdit:false
        });

        OpenModal();
    }

    const OpenModal =() =>
    {
        let addFilterModalInstanceElem = window.document.getElementById('create_filter_modal');
        window.M.Modal.init(addFilterModalInstanceElem,{
            onOpenEnd: onAddFilterModalOpenEnd,
            onCloseEnd:onFilterModalCloseEnd
        });

        let instance = window.M.Modal.getInstance(addFilterModalInstanceElem);
        instance.open();

        console.log(addFilterModalInstanceElem);
    }

    const CloseModal = ()=>{
        let addFilterModalInstanceElem = window.document.getElementById('create_filter_modal');

        let instance = window.M.Modal.getInstance(addFilterModalInstanceElem);
        instance.close();

    }

    const newFilterAllUsersCheckboxClickHandler = e =>{
        if(form.usersList.length === users.length)
        {
            setform({...form, usersList:[], newUsers: false})
        }
        else
        {
            let selectedUsers = [];
            for(let i=0;i<users.length;i++)
            {
                selectedUsers.push(users[i]._id);
            }
            setform({...form, usersList:selectedUsers, newUsers: true})
        }
    }

    const newFilterSelectedUserChangeHandler = e =>{

        const userId = e.target.dataset.user;

        let selectedUsers = [...form.usersList];

        let index = selectedUsers.indexOf(userId);
        if(index === -1)
        {
            selectedUsers.push(userId);
        }
        else
        {
            selectedUsers.splice(index, 1);
        }

        setform({...form, usersList:selectedUsers})

    }

    const isCurrentUserSelected = () =>
    {
        return form.usersList.indexOf(userId) !== -1;
    }

    const CurrentUserSelectionChanged = () =>
    {
        let selectedUsers = [...form.usersList];

        let index = selectedUsers.indexOf(userId);
        if(index === -1)
        {
            selectedUsers.push(userId);
        }
        else
        {
            selectedUsers.splice(index, 1);
        }

        setform({...form, usersList:selectedUsers})
    }

    const newFilterAllMCToggled = e =>
    {
        let newVal = !form.allMC;

        if(newVal)
        {
            let selectedMC = [];
            for(let i=0;i<mcSettings.length;i++)
            {
                let mcID = mcSettings[i]._id;
                if( mcSettings[i].parentMc){
                    mcID = mcSettings[i].parentMc._id;
                }
                selectedMC.push(mcID);
            }

            setform({...form, mcList:selectedMC, allMC: newVal});
        }
        else
        {
            setform({...form, allMC: newVal});
        }
    }

    const newFilterMcSelectboxChanged = e =>{

        const mcID = e.target.dataset.mcid;

        let mcList = [...form.mcList];

        let allMC = form.allMC;

        let index = mcList.indexOf(mcID);
        if(index === -1)
        {
            mcList.push(mcID);
        }
        else
        {
            mcList.splice(index, 1);
            allMC = false;
        }

        setform({...form, mcList:mcList,allMC: allMC})
    }

    const newFilterMcSelectChangeHandler = e=>{
        e.preventDefault();
    }

    const onAddFilterModalOpenEnd = ()=>{
        window.M.FormSelect.init( window.document.getElementById('newFilterMcSelect'));

        window.M.updateTextFields();
    }

    const onFilterModalCloseEnd = ()=>{
        navigate('/companyFilters');
    }

    const verifyFilterHandler = async () =>{
        try {
            const data = await  request(`/api/broker/update/verify/`, 'PUT', {filterId:form.updateFilterId},true);
            message(data.message);

            await fetchFilters();
        }
        catch (e) {

        }
    }


    const toggleFilterVisibility = async () =>{
        try {

            const data = await  request(`/api/broker/update/isactive/`, 'PUT', {filterId:form.updateFilterId},true);
            message(data.message);

            await fetchFilters();

            setform({...form,isVisible:!form.isVisible});
        }
        catch (e) {

        }
    }

    const searchClickHandler = async e =>{

        let name = document.getElementById("s_companyName").value.trim();
        setSearchForm({...searchForm,name:name});
        searchForm.name = name;

        let mcList;
        if(!searchForm.allMC) {
            let mcSelectInstance = window.M.FormSelect.getInstance( window.document.getElementById('s_mcList'));
            mcList = mcSelectInstance.getSelectedValues();

            if (mcList.length === 0) {
                message("Choose minimum 1 MC");
                return;
            }
        }else {
            mcList = [];
        }
        setSearchForm({...searchForm,mcList:mcList});
        searchForm.mcList = mcList;


        await fetchFilters();
    }

    const resetSearchClickHandler = async ()=>{
        document.getElementById("s_companyName").value = "";

        setSearchForm({
            name:"",
            onlyCreator:true,
            teamWithMe:true,
            teamWithoutMe:true,
            type:"-1",
            onlyContacts:false,
            creator:null,
            allMC:true,
            mcList:[]
        });


    }

    const getColorFromType = (type) =>{
        let filter = filterTypes.find(o => o._id === type);
        if(filter)
        {
            return filter.color.className;
        }
        else
        {
            return ""
        }
    }

    const printCurrentSearchStatus = ()=>
    {
        let text = "";
        if(searchForm.name !== "") {
            text += " -> \""+searchForm.name+"\"";
        }
        else
        {
            text += " All Names";
        }

        if(searchForm.type  && searchForm.type !== "-1") {
            text += ", Type:\""+ filterTypes.find(o => o._id === searchForm.type).typeName+"\"";
        }
        else
        {
            text += ", All Types";
        }

        
        return text;
    }

    return(
        <>

            <div className="card">

                {/*<div className="input-field col s12">*/}
                {/*    <input id="testname" type="text" name="name"  value={testName} onChange={(event)=>setTestName(event.target.value)} />*/}
                {/*    <label htmlFor="testname">Company Name</label>*/}
                {/*</div>*/}
                {/*<div className="input-field col s12">*/}
                {/*    <input id="testcontact"  type="text" name="name"  value={testContact} onChange={(event)=>setTestContact(event.target.value)} />*/}
                {/*    <label htmlFor="testcontact">Company contact</label>*/}
                {/*</div>*/}
                {/*<button className="btn green col s2" style={{marginBottom:10}} onClick={()=>{GetFiltersForBroker()}}>Test</button>*/}

                <div>
                    <ul className="collapsible">
                        <li>
                            <div className="collapsible-header">Search {printCurrentSearchStatus()}</div>
                            <div className="collapsible-body no-padding">
                                <div className="row">
                                    <div className="col s4">
                                        <div className="input-field">
                                            <input id="s_companyName" className="autocomplete" placeholder="Any" autoComplete="off" type="text"   />
                                            <label htmlFor="s_companyName">Company Name</label>
                                        </div>
                                        <div className="input-field">
                                            <span>Company filter type</span>
                                            <select
                                                name="type"
                                                value={searchForm.type}
                                                onChange={(event)=>setSearchForm({...searchForm, type:event.target.value})}
                                                // defaultValue={"-1"}
                                                className="browser-default"
                                                style={{display:"inline-block",width:"70%"}}
                                            >
                                                <option key={-1} value="-1">All</option>
                                                {filterTypes.map((filterType,index) =>{
                                                    return (
                                                        <option key={index} value={filterType._id}>{filterType.typeName}</option>
                                                    )
                                                })}
                                            </select>
                                            <span className={`colorPickerBtn ${getColorFromType(searchForm.type)}`} style={{display:"inline-block",width:"43px",height:"43px"}}></span>
                                        </div>
                                        <label className="input-field">
                                            <input type="checkbox"
                                                   checked={searchForm.allMC ? "checked" : ""}
                                                   onChange={e => {
                                                       setSearchForm({...searchForm, allMC: !searchForm.allMC})
                                                   }} className="filled-in"/>
                                            <span>For All MCs</span>
                                        </label>

                                        <div style={{display:(searchForm.allMC?"none":"block")}} className="input-field">
                                            <select multiple className="mc_select" id="s_mcList">
                                                    {mcSettings.map(function (mc, i) {
                                                        
                                                        let mcID = mc._id;
                                                        let mcName = mc.name;
                                                        if(mc.parentMc){
                                                            mcID = mc.parentMc._id;
                                                            mcName = mc.parentMc.name;
                                                        }
                                                        return (
                                                            <option  key={i} selected={searchForm.mcList.indexOf(mcID) !==-1?"selected":""} value={mcID}> {mcName}</option>
                                                        );
                                                    })}
                                            </select>
                                            <label>MC</label>
                                        </div>

                                    </div>
                                    <div className="col s8">
                                        <div className="row">
                                            <h6>Show Filters for</h6>
                                            <label className="input-field col ">
                                                <input type="checkbox"
                                                       checked={searchForm.onlyCreator ? "checked" : ""}
                                                       onChange={e => {
                                                           setSearchForm({...searchForm, onlyCreator: !searchForm.onlyCreator})
                                                       }} className="filled-in"/>
                                                <span>Only Me</span>
                                            </label>
                                            <label className="input-field col">
                                                <input type="checkbox"
                                                       checked={searchForm.teamWithMe ? "checked" : ""}
                                                       onChange={e => {
                                                           setSearchForm({...searchForm, teamWithMe: !searchForm.teamWithMe})
                                                       }} className="filled-in"/>
                                                <span>Team with Me</span>
                                            </label>
                                            <label className="input-field col">
                                                <input type="checkbox"
                                                       checked={searchForm.teamWithoutMe ? "checked" : ""}
                                                       onChange={e => {
                                                           setSearchForm({...searchForm, teamWithoutMe: !searchForm.teamWithoutMe})
                                                       }} className="filled-in"/>
                                                <span>Team without Me</span>
                                            </label>
                                        </div>
                                        <div className="row">
                                            <label className="input-field col ">
                                                <input type="checkbox"
                                                       checked={searchForm.onlyContacts ? "checked" : ""}
                                                       onChange={e => {
                                                           setSearchForm({...searchForm, onlyContacts: !searchForm.onlyContacts})
                                                       }} className="filled-in"/>
                                                <span>Contacts Only</span>
                                            </label>
                                        </div>

                                    </div>


                                </div>   
                                <button className="btn col s2 deep-orange lighten-1" style={{margin:"10px"}} onClick={()=>{resetSearchClickHandler()}}><i className="material-icons" style={{verticalAlign:"top"}}>clear</i> Reset</button>

                                <button className="btn green col s2" style={{margin:"10px"}} onClick={()=>{searchClickHandler()}}><i className="material-icons" style={{verticalAlign:"top"}}>search</i> Search</button>
                            </div>
                        </li>
                    </ul>
                </div>

                {mcSettings.length === 0 && mcSettingsReceived?(
                    <div className="row">
                        <div className="col s12 center">
                            <h5>Add your MC in Lines tab before creating filters</h5>
                        </div>
                    </div>
                ):(
                    <button className="btn green col s2" style={{margin:"10px"}} onClick={()=>{AddOrEditFilterClickHandler()}}>Create Filter</button>
                )}


                <div id="create_filter_modal" className="modal height90">
                    <div className="modal-content">
                        <div className="row">
                            <div className="col s12">
                                <h6>{form.createFormMode?"New Filter":"Update \""+form.updateFilterPrevName+"\" filter"}</h6>
                                <div className="row">

                                    <div className="col s6">

                                        <div className="input-field col s12">
                                            <input id="newCompanyName" disabled={form.canEdit?"":"disabled"} type="text" name="name"  value={form.name} onChange={(event)=>setform({...form, [event.target.name]:event.target.value})} />
                                            <label htmlFor="newCompanyName">Company Name</label>
                                        </div>

                                        <div className="input-field col s12">
                                            <span>Company filter type</span>
                                            <select
                                                name="type"
                                                disabled={form.canEdit?"":"disabled"}
                                                value={form.type}
                                                onChange={(event)=>setform({...form, [event.target.name]:event.target.value})}
                                                // defaultValue={"-1"}
                                                className="browser-default"
                                                style={{display:"inline-block",width:"70%"}}
                                            >
                                                <option key={-1} value="-1">Choose</option>
                                                {filterTypes.map((filterType,index) =>{
                                                    return (
                                                        <option  key={index} value={filterType._id}>{filterType.typeName}</option>
                                                    )
                                                })}
                                            </select>
                                            <span className={`colorPickerBtn ${getColorFromType(form.type)}`} style={{display:"inline-block",width:"43px",height:"43px"}}></span>
                                        </div>

                                        <div className="input-field col s12">
                                            <input id="newCompanyComment" type="text" name="comment"                                                  disabled={form.canEdit?"":"disabled"}
                                                   value={form.comment} onChange={(event)=>setform({...form, [event.target.name]:event.target.value})} />
                                            <label htmlFor="newCompanyComment">Comment</label>
                                        </div>




                                        <div className="input-field col s12">
                                            <div className="row" style={{marginBottom: "0px"}}>
                                                <div className="col s12">
                                                    <label>
                                                        <input type="checkbox"
                                                               disabled={form.canEdit?"":"disabled"}
                                                               checked={form.allMC ? "checked" : ""}
                                                               onChange={e => {
                                                                   newFilterAllMCToggled(e);
                                                               }} className="filled-in"/>
                                                        <span> All MC always</span>
                                                    </label>
                                                </div>

                                            </div>


                                            {
                                                mcSettings.length > 0 ? mcSettings.map((mc, index) => {

                                                    let mcID = mc._id;
                                                    let mcName = mc.name;
                                                    if(mc.parentMc){
                                                        mcID = mc.parentMc._id;
                                                        mcName = mc.parentMc.name;
                                                    }

                                                    return (
                                                        <div  key={index} className="row" style={{marginBottom: "0px"}}>
                                                            <div className="col s12">
                                                                <label>
                                                                    <input type="checkbox" data-mcid={mcID}
                                                                           disabled={form.canEdit?"":"disabled"}
                                                                           checked={form.mcList.indexOf(mcID) !==-1 ? "checked" : ""}
                                                                           onChange={newFilterMcSelectboxChanged}
                                                                           className="filled-in"/>
                                                                    <span> MC: {mcName}</span>
                                                                </label>
                                                            </div>

                                                        </div>
                                                    )
                                                }) : ""
                                            }
                                        </div>

                                        {/*<div style={{display:(form.allMC?"none":"block")}} className="input-field col s12">*/}
                                        {/*    <select multiple*/}
                                        {/*            disabled={form.canEdit?"":"disabled"}*/}
                                        {/*            id="newFilterMcSelect"*/}
                                        {/*            className="mc_select"*/}
                                        {/*            onChange={newFilterMcSelectChangeHandler}>*/}
                                        {/*            {mcSettings.map(function (mc, i) {*/}

                                        {/*                let mcID = mc._id;*/}
                                        {/*                let mcName = mc.name;*/}
                                        {/*                if(mc.parentMc){*/}
                                        {/*                    mcID = mc.parentMc._id;*/}
                                        {/*                    mcName = mc.parentMc.name;*/}
                                        {/*                }*/}

                                        {/*                return (*/}
                                        {/*                    <option  key={i} selected={form.mcList.indexOf(mcID) !==-1?"selected":""} value={mcID}> {mcName}</option>*/}
                                        {/*                );*/}
                                        {/*            })}*/}
                                        {/*    </select>*/}
                                        {/*    <label>MC</label>*/}
                                        {/*</div>*/}

                                    </div>
                                    <div className="col s6">

                                        <div className="col s12">
                                            <label>
                                                <input type="checkbox"
                                                       disabled={form.canEdit?"":"disabled"}
                                                       checked={form.onlyOnContacts ? "checked" : ""}
                                                       onChange={e => {
                                                           setform({...form, onlyOnContacts: !form.onlyOnContacts})
                                                       }} className="filled-in"/>
                                                <span>Filter only contact numbers</span>
                                            </label>
                                            <div style={{
                                                padding: "7px",
                                                marginBottom:"10px",
                                                border: "1px solid grey",
                                                borderRadius: "6px",
                                                display: (form.onlyOnContacts ? "block" : "none")
                                            }}>

                                                <div className="row" style={{marginBottom: "0px"}}>
                                                {
                                                    form.contacts.length > 0 ? form.contacts.map((contact, index) => {
                                                        return (
                                                                <>
                                                                    <div className="col s12" key={index}>
                                                                        <div className="col s9">
                                                                            <input  type="text" disabled={form.canEdit?"":"disabled"} placeholder="Contact Phone/Email" value={contact} style={{height:"31px"}} data-contactIndex={index} onChange={contactValueChangedHandler}/>
                                                                        </div>
                                                                        <div className="col s2">
                                                                            <button className="btn waves-effect waves-light  red lighten-1"
                                                                                    style={{
                                                                                        marginRight: '15px',
                                                                                        padding: "0",
                                                                                        width: "30px",
                                                                                        height: "30px"
                                                                                    }}
                                                                                    data-contactIndex={index}
                                                                                    disabled={form.canEdit?"":"disabled"}
                                                                                    onClick={deleteContactClickHandler}
                                                                            >
                                                                                <i className="material-icons" onClick={(e)=>{e.preventDefault()}}
                                                                                   style={{fontSize: '15px', marginTop: "-5px"}}>delete</i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        )
                                                    }) : (<></>)}
                                                    <div className="col s12" style={{marginTop:"30px"}}>
                                                        <div className="col s9">
                                                            <input disabled={form.canEdit?"":"disabled"}
                                                                   placeholder="New Contact Phone/Email"
                                                                   value={newContactValue}
                                                                   onChange={(e)=>setNewContactValue(e.target.value)}
                                                                   // style={{display:(!parseInt(newContactValue[0]))?"initial":"none"}}
                                                            />
                                                        </div>
                                                        <div className="col s2">
                                                            <button className="btn waves-effect waves-light  green darken-3"
                                                                    style={{
                                                                        marginRight: '15px',
                                                                        padding: "0",
                                                                        width: "30px",
                                                                        height: "30px"
                                                                    }}
                                                                    disabled={form.canEdit?"":"disabled"}
                                                                    onClick={addContactClickHandler}
                                                            >
                                                                <i className="material-icons"  onClick={(e)=>{e.preventDefault()}}
                                                                   style={{fontSize: '15px', marginTop: "-5px"}}>add</i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col s12" style={{marginTop:"10px"}}>
                                            <h6>Visibility</h6>
                                            <label className="col s6">
                                                <input name="newFilterGroup" type="radio" checked={form.onlyCreator}
                                                       disabled={form.canEdit?"":"disabled"}
                                                       onChange={e => {
                                                           setform({...form, onlyCreator: true})
                                                       }}/>
                                                <span>Only For {form.createFormMode || (!form.createFormMode && form.creator._id.toString() === userId)?"Me":"Creator"}</span>
                                            </label>
                                            <label className="col s6">
                                                <input name="newFilterGroup" type="radio" checked={!form.onlyCreator}
                                                       disabled={form.canEdit?"":"disabled"}
                                                       onChange={e => {
                                                           setform({...form, onlyCreator: false});
                                                       }}/>
                                                <span>For Team</span>
                                            </label>
                                        </div>
                                        <div className="col s12" style={{display:(form.onlyCreator?"none":"block")}}>
                                            <h6>Users</h6>
                                            <div  style={{display:(userType==="agent"?"none":"block")}}>
                                                <label>
                                                    <input type="checkbox"
                                                           checked={form.usersList.length === users.length ? "checked" : ""}
                                                           onChange={newFilterAllUsersCheckboxClickHandler}
                                                           className="filled-in"/>
                                                    <span>All</span>
                                                </label>

                                                <div style={{width: "380px"}}>
                                                    <ul className="collapsible">
                                                        <li>
                                                            <div className="collapsible-header">Users List  (active: {form.usersList.length} of {users.length} )</div>
                                                            <div className="collapsible-body no-padding">
                                                                {
                                                                    users.length > 0 ? users.map((user, index) => {
                                                                        return (
                                                                            <div  key={index} className="row" style={{marginBottom: "0px"}}>
                                                                                <div className="col s1">
                                                                                    <label>
                                                                                        <input type="checkbox" data-user={user._id}
                                                                                               checked={form.usersList.indexOf(user._id) !== -1 ? "checked" : ""}
                                                                                               onChange={newFilterSelectedUserChangeHandler}
                                                                                               className="filled-in"/>
                                                                                        <span></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col s5">
                                                                                    <label>
                                                                                        {user.name}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col s6">
                                                                                    <label>
                                                                                        {user.email}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) : ""
                                                                }
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div style={{marginTop: "5px"}}>
                                                    <label>
                                                        <input type="checkbox" checked={form.newUsers ? "checked" : ""}
                                                               onChange={e => {
                                                                   setform({...form, newUsers: !form.newUsers})
                                                               }} className="filled-in"/>
                                                        <span><b>New Users</b></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div style={{marginTop: "15px"}}>
                                                <label>
                                                    <input type="checkbox" checked={isCurrentUserSelected() ? "checked" : ""}
                                                           onChange={e => {
                                                               CurrentUserSelectionChanged()
                                                           }} className="filled-in"
                                                           disabled={form.canEdit?"":"disabled"}/>
                                                    <span><b>Show for Me - {userName} {userEmail}</b></span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col s12">
                                {
                                    !form.isVerified && !form.createFormMode && !form.viewMode?
                                        <div style={{display:"inline-block",textAlign:"left",marginRight:"31px"}}>
                                            <div>
                                                <span style={{backgroundColor:"rgb(255, 173, 2)",borderRadius:"3px",padding:"3px",color:"white",fontSize:"14px"}}>Unverified (Added by Agent)</span>
                                            </div>
                                        </div>
                                        :""
                                }
                                {
                                    !form.createFormMode &&  !form.viewMode?
                                        <div style={{display:"inline-block",textAlign:"left"}}>
                                            <div>
                                                <span style={{backgroundColor:(form.isVisible ? "#42c72e":"#ffa3a3"),borderRadius:"3px",padding:"3px",color:"white",fontSize:"14px"}}>{!form.isVisible ? "Disabled":"Active"}</span>
                                            </div>
                                            <button className="btn waves-effect waves-light" style={{marginRight:'1rem',marginBottom:"10px"}}
                                                    onClick={() => toggleFilterVisibility()}
                                            >
                                                <i className="material-icons">{form.isVisible ? "visibility_off":"visibility"}</i>
                                                <span style={{verticalAlign:"top",paddingLeft:"2px"}}>{form.isVisible ? "Disable":"Activate"}</span>
                                            </button>
                                        </div>:""
                                }


                                <button className="btn green" style={{marginBottom:10}} onClick={CreateOrUpdateFilterClick}>{(userType==="manager"&&!form.isVerified&&!form.createFormMode)?"Verify and ":""} {form.createFormMode?"Create Filter":"Update Info"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {(filters) ? <FiltersList  loggedInUserType={userType} loggedInUserId={userId} filters={filters} typeOverrides={filterTypes} disabledFilters={disabledFilters} listChanged={listChangedCallback} editFilterCallback={(filter)=>{AddOrEditFilterClickHandler(filter)}} showFilterCallback={ShowFilterClickHandler} mcSettings={mcSettings}/> : <Loader />}
            <a className="right" style={{marginTop:10}} href="https://sudofreight.com/create-broker-filters/" target="_blank">Help: How to create filters</a>

        </>
    )
}