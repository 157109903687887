import React, {useState} from 'react';
import {useHttp} from "../hooks/http.hook";
import {useCallback, useContext, useEffect} from "react";
import {AuthContext} from "../context/auth.context";
import {Loader} from "../components/Loader";
import {UsersList} from "../components/UserList";
import {  useNavigate} from "react-router-dom";
import {useMessage} from "../hooks/message.hook";

import "materialize-css";


export const UsersPage = () =>{
    const navigate = useNavigate();

    const [mcListLength,setMCListLength] = useState(0);
    const [users, setUsers] = useState([]);
    const {loading, request} = useHttp();
    const {userType} = useContext(AuthContext);
    const message = useMessage();


    const fetchUsers = useCallback(async ()=>{
        try{
            const fetchedUsers = await request("/api/user","GET",null,true);
            setUsers(fetchedUsers);

            const data = await request("/api/mcSettings","GET", null,true);
            console.log(data);
            setMCListLength(data.length);

        }catch (e) {

        }
    },[request]);

    const callbackFunction = (childData) => {
        fetchUsers();
    }

    useEffect(()=>{
        fetchUsers();
    },[]);

    const AddUserClick =()=>{
        // if(userType !== "admin" && mcListLength === 0)
        // {
        //     message("Please add MC in 'Lines' page, before adding agents");
        //     return;
        // }

        navigate("/createUser/");
    }

    if(loading)
    {
        return <Loader />
    }

    return(
        <>
            <button className="btn yellow darken-4" style={{marginTop:10}} onClick={AddUserClick}>Add User</button>
            {!loading && <UsersList loggedInUserType={userType} users={users} callbackFunction={callbackFunction}/>}
        </>
    )
}