import React, {useState} from 'react';
import {useHttp} from "../hooks/http.hook";
import {useCallback, useContext, useEffect} from "react";
import {AuthContext} from "../context/auth.context";
import {Loader} from "../components/Loader";
import {ActiveLoadsList} from "../components/ActiveLoadsList";
import {useMessage} from "../hooks/message.hook";

import 'materialize-css';

export const ActiveLoadsPage = () =>{

    const [loadsData,setLoadsData] = useState([]);
    const {loading, request} = useHttp();
    const {userType} = useContext(AuthContext);
    const message = useMessage();

    const fetchData = useCallback(async ()=>{
        try{
            if(userType === "manager")
            {
                const data = await request("/api/activeLoad/team","POST",null,true);
                setLoadsData(data);
            }
            else
            {
                const data = await request("/api/activeLoad/my","GET",null,true);
                setLoadsData(data);
            }

        }catch (e) {

        }
    },[request]);

    const listChanged = () =>{
        fetchData();
    }

    useEffect(()=>{
        fetchData();
    },[]);


    useEffect(()=>{
        window.M.AutoInit();
    },[]);


    return(
        <>
            {(!loading) ? <ActiveLoadsList  listData={loadsData} listChanged={listChanged} userType={userType}/>  : <Loader />}
        </>
    )
}