import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useHttp} from "../hooks/http.hook";
import {Loader} from "../components/Loader";

export const EmailVerifyPage = () =>{
    const [message,setMessage] = useState('');
    const {request,loading} = useHttp();
    let hash = useParams().hash;

    const checkHash = useCallback(async ()=>{
        try{
            const fetched = await request(`/api/user/verify/${hash}`,"GET",null,)
            setMessage(fetched.message);
        }catch (e) {
            setMessage(e.message);
        }
    }, [request])

    useEffect(()=>{checkHash()},[]);

    if(loading)
    {
        return <Loader />
    }

    return(

        <>
            <div className="row">
                <div className="container">
                    <div className="emailMessageContainer">
                        {message}
                    </div>

                </div>

            </div>

        </>
    )
}