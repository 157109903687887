import React, {useState} from 'react';
import {useHttp} from "../hooks/http.hook";
import {useCallback, useEffect,useContext} from "react";
import {Loader} from "../components/Loader";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";
import {FilterTypesList} from "../components/FilterTypesList";
import {useFilterTypes} from "../hooks/filterTypes.hook";


import 'materialize-css';

export const FiltersPage = () =>{
    const [filterTypes, setFilterTypes] = useState([]);
    const {loading, request} = useHttp();
    const message = useMessage();
    const {userType} = useContext(AuthContext);

    const [form, setform] = useState({
        name:"",
        type:"",
    })
    const {getFilters} = useFilterTypes();

    const fetchUserFilterTypes = useCallback(async ()=>{
        const data= await getFilters();
        setFilterTypes(data);
    },[request]);

    const nameChangeCallback = async (newFilters) =>{
        try {

            const data = await  request(`/api/user/update/filterOverride/`, 'PUT', {newFilters},true);

        }catch (e) {
            message(e.message);
        }
    }

    useEffect(()=>{
        fetchUserFilterTypes();
        window.M.AutoInit();
    },[]);


    const AddFilterClickHandler =async e=>{
        e.preventDefault();
        try {

            const data = await  request('/api/broker/add', 'POST', {...form},true);

            message(data.message);
        }catch (e) {

        }
    }

    return(
        <>
            {filterTypes.length > 0 &&  <FilterTypesList _filterTypes={filterTypes} userType={userType} nameChangeCallback={nameChangeCallback}/>}
        </>
    )
}