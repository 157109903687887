import React, {useState,useContext} from 'react';
import {useHttp} from "../hooks/http.hook";
import {useCallback, useEffect} from "react";
import {Loader} from "../components/Loader";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";
import {PaymentHistoryList} from "../components/PaymentHistoryList";
import {NewPaymentComponent} from "../components/NewPaymentComponent";



export const PaymentPage = () =>{

    return(
        <>
            <NewPaymentComponent />
            <PaymentHistoryList />
        </>
    );
}