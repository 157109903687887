import React, {useState, useMemo,useContext} from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter,useFilters,useFlexLayout } from "react-table";
import {useHttp} from "../hooks/http.hook";
import {useMessage} from "../hooks/message.hook";


export const ActiveLoadsList = ({listData,listChanged,userType}) =>
{
    const {loading, request} = useHttp();
    const message = useMessage();

    const managerColumns = [
        {
            Header: "Agent",
            accessor: "parent.name",
            Filter:SelectColumnFilter,
        },
        {
            Header: "Company",
            accessor: "brokerName",
            Filter:SelectColumnFilter,
        },
        {
            Header: "Load",
            accessor: "load",
            Cell: cellProps => {
                return (
                    <>
                        <span>{`${cellProps.row.original.origin} -> ${cellProps.row.original.dest} : ${cellProps.row.original.loadDate}`}</span>
                    </>
                )
            },
        },
        {
            Header: "Date",
            accessor: "date",
            Cell: cellProps => {
                return (
                    <>
                        {new Date(cellProps.row.original.date).toLocaleString()}
                    </>
                )
            },
            Filter:SelectColumnFilter,
        },
        {
            Header: "Actions",
            accessor: "actions",
            Cell: cellProps => {
                return <CustomActionCell {...cellProps} />;
            }
        }
    ];

    const columnProps = managerColumns;

    const columns = useMemo(
        () => columnProps,
        []
    );

    const data = useMemo(
        () => listData,
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        prepareRow,
        state,
        setGlobalFilter
    } = useTable({
        columns,
        data,
    },useFilters,useGlobalFilter,useSortBy,usePagination);

    const {globalFilter} = state;

    function CustomActionCell(cellProps) {

        return (
            <>
                <button className="btn waves-effect waves-light  red darken-3" style={{marginLeft:'1rem'}}
                        onClick={() => deleteRowHandler(cellProps.row.original._id)}
                >
                    <i className="material-icons">delete</i>
                </button>
            </>
        );
    }

    const deleteRowHandler = async (entryId) =>{
        try {
            const data = await  request(`/api/activeLoad/delete/`, 'POST', {id:entryId},true);
            message(data.message);

            listChanged();
        }
        catch (e) {

        }
    }

    function SelectColumnFilter({
                                    column: { filterValue, setFilter, preFilteredRows, id },
                                }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])

        // Render a multi-select box
        return (
            <select
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
            >
                <option value="">All</option>
                {options.map((option, i) => (
                    <option key={i} value={option}>{option}
                    </option>
                ))}
            </select>
        )
    }

    const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

    const getStyles = (props, align = 'left') => [
        props,
        {
            style: {
                overflow:'initial'
                // justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
                // alignItems: 'flex-start',
                // display: 'flex',
            },
        },
    ]
    //
    // if(listData.length === 0)
    // {
    //     return (
    //         <p className="center">No History</p>
    //     )
    // }

    return(

        <>
            <div className="card material-table">

                <div className="table-header">
                    <span className="table-title">My {userType==="manager"?"team's":""} active loads</span>
                    <div className="actions">
                        <input value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}/>
                        <span><i className="material-icons">search</i></span>
                    </div>
                </div>

                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th >
                                    <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        <span>{column.isSorted? (column.isSortedDesc? '↓':'↑'):''}</span>
                                    </div>

                                    <div>
                                        {(column.canFilter && column.Filter) ? column.render("Filter"):null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td  {...cell.getCellProps(cellProps)}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                <div className="pagination table-footer">

                    <label>
                        Page {' '}
                        <label>
                            {pageIndex + 1} of {pageOptions.length}
                        </label>{' '}
                    </label>
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    <ul class="material-pagination">
                        <li className="paginate_button">
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <i className="material-icons">chevron_left</i>
                            </button>
                        </li>
                        <li className="paginate_button">
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                <i className="material-icons">chevron_right</i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

        </>

    )
}