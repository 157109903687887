import React, {useState, useMemo,useContext} from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter,useFilters,useFlexLayout } from "react-table";
import {useHttp} from "../hooks/http.hook";
import {useMessage} from "../hooks/message.hook";


export const FiltersList = ({loggedInUserType,loggedInUserId,filters,typeOverrides,listChanged, editFilterCallback,showFilterCallback,disabledFilters,mcSettings}) =>
{
    filters = filters.reverse();

    const {loading, request} = useHttp();
    const message = useMessage();
    const [filterToBeDeleted, setFilterToBeDeleted] = useState({entryId:null, companyName:""});

    const managerColumns = [
        {
            Header: "Name",
            accessor: "name",
        },

        {
            Header: "Contacts",
            Cell: cellProps => {
                return (
                    <span>{!cellProps.row.original.onlyOnContacts?"All":
                        <>
                            {cellProps.row.original.contacts[0] + (cellProps.row.original.contacts.length>1?", ...":"")}
                        </>}
                    </span>
                )
            },
        },
        {
            Header: "Type",
            accessor: "type",
            Cell: cellProps => {

                if(filters.length === 0) return null;

                return (
                    <>
                        <span className={`colorPickerBtn ${typeOverrides.find(o => o._id === cellProps.row.original.type).color.className}`}>{GetTypeNameFromId(cellProps.row.original.type)}</span>
                    </>
                )
            },
        },

        {
            Header: "Comment",
            accessor: "comment",
        },
        {
            Header: "Users",
            Cell: cellProps => {
                return (
                    <span>{cellProps.row.original.onlyCreator?"Only Me":"Team "}{!cellProps.row.original.onlyCreator?visibilityForMeSpan(cellProps):""}</span>
                )
            },
        },
        {
            Header: "MC",
            Cell: cellProps => {
                return (
                    <span>{cellProps.row.original.allMC?"All MC":
                        <>
                            {cellProps.row.original.mcList.map((mc, i) => (
                                <>{i!==0?",":""} {getMcNameFromId(mc)} </>
                            ))}
                        </>}
                    </span>
                )
            },
        },
        {
            Header: "Creator",
            accessor: "creator.name",
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: cellProps => {
                return <CustomStatusCell {...cellProps} />;
            },
        },

        {
            Header: "Actions",
            accessor: "actions",
            Cell: cellProps => {
                return <CustomManagerActionCell {...cellProps} />;
            },
        }

    ];

    const agentColumns = [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Contacts",
            Cell: cellProps => {
                return (
                    <span>{!cellProps.row.original.onlyOnContacts?"All":
                        <>
                            {cellProps.row.original.contacts[0] + (cellProps.row.original.contacts.length>0?", ...":"")}
                        </>}
                    </span>
                )
            },
        },
        {
            Header: "Type",
            accessor: "type",
            Cell: cellProps => {

                if(filters.length === 0) return null;

                return (
                    <>
                        <span className={`colorPickerBtn ${typeOverrides.find(o => o._id === cellProps.row.original.type).color.className}`}>{GetTypeNameFromId(cellProps.row.original.type)}</span>
                    </>
                )
            },
            Filter:SelectColumnFilter,
        },
        {
            Header: "Comment",
            accessor: "comment",
        },
        {
            Header: "Users",
            Cell: cellProps => {
                return (
                    <span>{cellProps.row.original.onlyCreator?"Only Me":"Team "}{!cellProps.row.original.onlyCreator?visibilityForMeSpan(cellProps):""}</span>
                )
            },
        },
        {
            Header: "MC",
            Cell: cellProps => {
                return (
                    <span>{cellProps.row.original.allMC?"All MC":
                        <>
                            {cellProps.row.original.mcList.map((mc, i) => (
                                <>{i!==0?",":""} {getMcNameFromId(mc)} </>
                            ))}
                        </>}
                    </span>
                )
            },
        },

        {
            Header: "Creator",
            accessor: "creator.name",
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: cellProps => {
                return <CustomStatusCell {...cellProps} />;
            },
        },

        {
            Header: "Actions",
            accessor: "actions",
            Cell: cellProps => {
                return <CustomAgentActionCell {...cellProps} />;
            },
        }
    ]

    const columnProps =loggedInUserType ==="manager"?managerColumns:agentColumns;


    function GetTypeNameFromId(id)
    {
        if(filters.length===0) return "";

        return typeOverrides.find(o => o._id === id).typeName;
    }

    function visibilityForMeSpan(cellProps)
    {
        let statusText = "";
        let backgroundColor ="";

        if(cellProps.row.original.usersList.indexOf(loggedInUserId) !==-1)
        {
            statusText = "and Me";
            backgroundColor ="#42c72e";
        }
        else
        {
            statusText = "without Me";
            backgroundColor = "#ffa3a3";
        }

        return (
            <>
                <span style={{backgroundColor:backgroundColor,borderRadius:"3px",padding:"3px",color:"white",fontSize:"10px"}}>{statusText}</span>
            </>
        );
    }

    function CustomStatusCell(cellProps) {

        let statusText = "";
        let backgroundColor ="";
        if(cellProps.row.original.isVerified)
        {
            if(cellProps.row.original.isVisible)
            {
                statusText = "Active";
                backgroundColor ="#42c72e";
            }
            else
            {
                statusText = "Disabled";
                backgroundColor = "#ffa3a3";
            }
        }
        else
        {
            statusText = "Unverified";
            backgroundColor = "#ffad02";
        }

        return (
            <>
                <span style={{backgroundColor:backgroundColor,borderRadius:"3px",padding:"3px",color:"white",fontSize:"10px"}}>{statusText}</span>
            </>
        );
    }

    function CustomManagerActionCell(cellProps) {

        return (
            <>
                <div className="nowrap">
                    <button className="btn waves-effect waves-light yellow darken-3"
                            onClick={() => editRowHandler(cellProps.row)}
                    >
                        <i className="material-icons">edit</i>
                    </button>
                    <button className="btn waves-effect waves-light  red darken-3" style={{marginLeft:'1rem'}}
                            onClick={() => DeleteRowOpenPopupHandler(cellProps.row.original._id,cellProps.row.original.name)}
                    >
                        <i className="material-icons">delete</i>
                    </button>
                </div>

            </>
        );
    }

    function CustomAgentActionCell(cellProps) {

        if(cellProps.row.original.creator
            && cellProps.row.original.creator._id === loggedInUserId
            && cellProps.row.original.onlyCreator
        )
        {
            return (
                <>
                    <div className="nowrap">

                        <button className="btn waves-effect waves-light yellow darken-3"
                                onClick={() => editRowHandler(cellProps.row)}
                        >
                            <i className="material-icons">edit</i>
                        </button>

                        <button className="btn waves-effect waves-light  red darken-3" style={{marginLeft:'1rem'}}
                                onClick={() => DeleteRowOpenPopupHandler(cellProps.row.original._id,cellProps.row.original.name)}
                        >
                            <i className="material-icons">delete</i>
                        </button>
                    </div>

                </>
            );
        }
        else
        {
           return (<>
                <div className="nowrap">

                    <button className="btn waves-effect waves-light yellow darken-3"
                            onClick={() => showRowHandler(cellProps.row)}
                    >
                        <i className="material-icons">remove_red_eye</i>
                    </button>

                </div>

            </>);

        }


    }

    function CustomVisibilityCell(cellProps) {

        let isvisible = disabledFilters.indexOf(cellProps.row.original._id) === -1;

        return (
            <>
                <div className="nowrap">
                    <span>{isvisible?"Visible":"Disabled for me"}</span><br />

                    <button className="btn waves-effect waves-light"
                            onClick={() => toggleFilterStatusHandler(cellProps.row.original._id, !isvisible)}
                    >
                        {isvisible?"Disable":"Enable"}
                    </button>
                </div>

            </>
        );
    }

    const columns = useMemo(
        () => columnProps,
        []
    );

    const data = useMemo(
        () => filters,
        [filters]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        prepareRow,
        state,
        setGlobalFilter
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0},
        autoResetPage: false
    },useFilters,useGlobalFilter,useSortBy,usePagination);

    const {globalFilter} = state;



    const editRowHandler = async (row) =>{
        editFilterCallback(row.original);
    }

    const showRowHandler = async (row) =>{
        showFilterCallback(row.original);
    }


    const toggleFilterStatusHandler = async (entryId,makeVisible) =>{

        if(makeVisible)
        {
            let index = disabledFilters.indexOf(entryId);
            disabledFilters.splice(index, 1);
        }
        else
        {
            disabledFilters.push(entryId);
        }

        try {

            const data = await  request(`/api/user/update/disabledFilters/`, 'POST', {disabledFilters:disabledFilters},true);
            message(data.message);

            //listChanged();
        }
        catch (e) {

        }
    }



    function SelectColumnFilter({
                                    column: { filterValue, setFilter, preFilteredRows, id },
                                }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])
        // Render a multi-select box
        return (
            <select
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                className="browser-default"
            >
                <option key={"-1"} value="">All</option>
                {options.map((option, i) => (
                    <option key={i} value={option}>
                        {GetTypeNameFromId(option)}
                    </option>
                ))}
            </select>
        )
    }

    const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

    const getStyles = (props, align = 'left') => [
        props,
        {
            style: {
                overflow:'initial'
                // justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
                // alignItems: 'flex-start',
                // display: 'flex',
            },
        },
    ]

    const getMcNameFromId = (id)=>
    {
        let mcSett = mcSettings.find(mcSett => (mcSett._id === id || (mcSett.parentMc && mcSett.parentMc._id === id)));

        if(mcSett)
        {
            return mcSett.parentMc?mcSett.parentMc.name:mcSett.name;
        }
    }

    const DeleteRowOpenPopupHandler = (entryId,name)=>{

        setFilterToBeDeleted({
            entryId: entryId,
            companyName:name
        })

        let modal = window.document.getElementById('delete_popup');
        window.M.Modal.init(modal,{});
        let instance = window.M.Modal.getInstance(modal);
        instance.open();
    }

    const DeleteFilterAgreeHandler = async  (e)=>{
        e.preventDefault();

       await deleteRowHandler(filterToBeDeleted.entryId)
    }


    const deleteRowHandler = async (entryId) =>{
        try {
            const data = await  request(`/api/broker/update/delete/`, 'PUT', {filterId:entryId},true);
            message(data.message);

            listChanged();
        }
        catch (e) {

        }
    }

    if(!filters.length)
    {
        return (
            <p className="center">No Filters</p>
        )
    }

    return(

        <>
            <div className="card material-table">

                <div className="table-header">
                    <span className="table-title">Filters List (Total: {filters.length})</span>
                    <div className="actions">
                        <input value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}/>
                        <span><i className="material-icons">search</i></span>
                    </div>
                </div>

                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup,i) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key ={i} >
                            {headerGroup.headers.map((column,j) => (
                                <th key={j}>
                                    <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        <span>{column.isSorted? (column.isSortedDesc? '↓':'↑'):''}</span>
                                    </div>

                                    <div>
                                        {(column.canFilter && column.Filter) ? column.render("Filter"):null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={i}>
                                {row.cells.map((cell) => {
                                    return <td  {...cell.getCellProps(cellProps)}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                <div className="pagination table-footer">

                    <label>
                        Page {' '}
                        <label>
                            {pageIndex + 1} of {pageOptions.length}
                        </label>{' '}
                    </label>
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    <ul className="material-pagination">
                        <li className="paginate_button">
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <i className="material-icons">chevron_left</i>
                            </button>
                        </li>
                        <li className="paginate_button">
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                <i className="material-icons">chevron_right</i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div id="delete_popup" className="modal small-modal">
                <div className="modal-content">
                    <h5>Delete Confirm</h5>
                    <span>Are you sure you want to delete <br /> {filterToBeDeleted.companyName}?</span>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12"  style={{marginBottom:10}}>
                            <button className="btn red modal-close" onClick={DeleteFilterAgreeHandler}>Delete</button>
                            <button className="btn grey modal-close"  style={{marginLeft:10}}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}