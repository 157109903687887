import {useState, useCallback} from 'react';
import {useHttp} from "./http.hook";


export const useMcSettings = () => {
    const {loading, request} = useHttp();

    const getMcSettings = useCallback(async ()=>{
        try{
            const data = await request("/api/mcSettings/","GET",null,true);

            return data;
        }catch (e) {

        }
    },[request]);

    const getMcSettingsByParentId = useCallback(async (parentId)=>{
        try{
            const data = await request("/api/mcSettings/byParentId/","POST", {parentId:parentId},true);

            return data;
        }catch (e) {

        }
    },[request]);

    const getParentMcSettings = useCallback(async ()=>{
        try{
            const data = await request("/api/mcSettings/parentMcSettings","GET",null,true);

            return data;
        }catch (e) {

        }
    },[request]);

    const getAllMcSettings = useCallback(async ()=>{
        try{
            const data = await request("/api/mcSettings/allMcSettings","GET",null,true);

            return data;
        }catch (e) {

        }
    },[request]);

    return  {getMcSettings, getParentMcSettings,getAllMcSettings,getMcSettingsByParentId }
}