import React, {useState,useContext} from 'react';
import {useHttp} from "../hooks/http.hook";
import {useCallback, useEffect} from "react";
import {Loader} from "../components/Loader";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";
import {PromoCodesList} from "../components/PromoCodesList";
import {CreatePromoCode} from "../components/CreatePromoCode";
import {RandomPromoCode} from "../components/RandomPromoCode";


export const PromoCodesPage = () =>{
    const {userType} = useContext(AuthContext);


    return(
        <>
            <RandomPromoCode/>
            <br/>
            <br/>
            <br/>
            <br/>
            {/*{userType === "admin" && <CreatePromoCode />}*/}
            {/*<PromoCodesList />*/}
        </>
    );
}