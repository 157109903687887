import {useState, useCallback, useEffect, useContext} from "react";
import {useHttp} from "../hooks/http.hook";
import {check} from "express-validator";
import {AuthContext} from "../context/auth.context";

const storageName = "userData";

export const useAuth = () =>{

    //const {token} = useContext(AuthContext)
    const [token, setToken] = useState(null);
    const [ready, setReady] = useState(false);
    const [userId, setUserId] = useState(null);
    const [userType, setUserType] = useState(null);
    const [userName, setUserName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(null);
    const {loading, request} = useHttp();

    const login = useCallback((jwtToken,id,type,name,email,isSubscriptionActive)=>{
        setToken(jwtToken);
        setUserId(id);
        setUserType(type);
        setUserName(name);
        setUserEmail(email);
        setIsSubscriptionActive(isSubscriptionActive);

        localStorage.setItem(storageName, JSON.stringify({
            userId:id,
            token:jwtToken,
            userType:type,
            userName:name,
            userEmail:email,
            isSubscriptionActive:isSubscriptionActive
        }))
    },[]);


    const logout = useCallback(()=>{
        console.log("logout");

        setToken(null);
        setUserId(null);
        setUserType(null);
        setUserName("");
        setUserEmail("");
        setIsSubscriptionActive(false);

        localStorage.removeItem(storageName);
    },[]);

    useEffect( ()=>{
        const  data = JSON.parse(localStorage.getItem(storageName));

        if(data && data.token)
        {
            login(data.token,data.userId,data.userType,data.userName,data.userEmail,data.isSubscriptionActive);
        }

        setReady(true);
    }, [login])

    return {login,logout, token, userId,ready, userType,userName,userEmail,isSubscriptionActive}
}