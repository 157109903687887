import React, {useState, useEffect, useContext} from 'react';
import {useHttp} from "../hooks/http.hook";
import {useNavigate} from "react-router-dom";

export const CreatePage = () =>{
	const navigate = useNavigate();
	const {request}  =useHttp();
	const [link,setLink] = useState('');

	useEffect(()=>{
		window.M.updateTextFields()
	},[])

	const keyDownHandler = async event =>
	{
		if(event.key === "Enter")
		{
			try {
				const data = await request("/api/link/generate","POST",{from:link},true);

				navigate(`/detail/${data.link._id}`);
				console.log(data);
			}
			catch (e) {

			}
		}
	}

	return(
		<div className="row">
			<div className="col s8 offset-s2" style={{paddingTop:'2rem'}}>
				<div className="input-field">
					<input placeholder="write your link"
						   id="link"
						   type="text"
						   value={link}
						   onChange={e => setLink(e.target.value)}
						   onKeyDown={keyDownHandler}
					/>
					<label htmlFor="link">Enter Link</label>
				</div>
			</div>
		</div>
	)
}