import {useState, useCallback} from 'react';
import {useHttp} from "./http.hook";


export const usePayment = () => {
    const {loading, request} = useHttp();

    const getMonthlyPrice =async()=>{
        try{
            const data = await request("/api/payment/monthlyprice","GET",null,true);
            return data;
        }catch (e) {

        }
    };

    const getPaymentHistory =async()=>{
        try{
            const data = await request("/api/payment/paymentHistory","GET",null,true);
            return data;
        }catch (e) {

        }
    };

    const GeneratePaymentLink =async(user,paymentType,form)=>{
        try{

            form.userToBePaidId = user._id;
            form.paymentType = paymentType;

            const data = await request("/api/payment/generateLink","POST", form ,true);

            if(data.orderId)
            {

            }

            return data;
        }catch (e) {

        }
    };

    const GetPaymentLink = async(orderId)=>
    {
        try{
            const data = await request("/api/payment/getPaymentLink","POST", {orderId:orderId} ,true);
            return data;
        }catch (e) {

        }
    }


    const GetArcaOrderStatus = async(orderId)=>
    {
        try{
            const data = await request("/api/payment/get_arca_status","POST", {orderId:orderId} ,true);
            return data;
        }catch (e) {

        }
    }

    const GetCurrentSubscription = async()=>{
        try{
            const data = await request(`/api/subscription/status`,"GET",null,true)
            return data;
        }catch (e) {

        }
    }

    const GetChildUserSubscriptions = async(parentId, includeParent)=>{
        try{
            const data = await request(`/api/subscription/childUsersSubscriptions`,"POST",{parentId:parentId,includeParent:includeParent},true)
            return data;
        }catch (e) {

        }
    }


    return  {getMonthlyPrice, GeneratePaymentLink, GetPaymentLink,GetArcaOrderStatus,getPaymentHistory,GetCurrentSubscription,GetChildUserSubscriptions}
}