import React, {useState,useContext} from 'react';
import {useCallback, useEffect} from "react";
import { useLocation } from 'react-router-dom'
import {usePayment} from "../hooks/payment.hook";

export const CurrentSubscriptionHeader = (params) =>{
    const [currentSubscription,setCurrentSubscription] = useState(null);
    const {GetCurrentSubscription} = usePayment();
    const location = useLocation();

    const fetchCurrentSubscription = async()=>{
        setCurrentSubscription(await GetCurrentSubscription());
    }

    const printActiveSubscriptionMessage = ()=>{

        if(currentSubscription.status.subscriptionDaysLeft < 7)
        {
            let message;
            let colorClass = "few-days-left";
            if(currentSubscription.status.subscriptionDaysLeft === 0)
            {
                colorClass = "expires-today";
                message = "Subscription Expires today."
            }
            else
            {
               message = parseInt(currentSubscription.status.subscriptionDaysLeft)+" days left to your subscription.";
            }

            return (
                <div className="subscription-header-message">
                    <div className={colorClass}>
                        {message} Pay now to extend subscription
                        {params.showbtn && (
                            <a className="green waves-effect waves-light btn" style={{marginLeft:"10px"}} href="/payment/"><i
                            className="material-icons left">credit_card</i>Pay Now</a>
                        )}

                    </div>
                </div>
            )
        }
        else
        {
            return null;
        }
    }

    const printExpiredSubscriptionMessage = ()=>{
        return (
            <div className="subscription-header-message">
                <div className="expired">
                    Your Subscription is Expired. Pay now to use Sudo Freight
                    {params.showbtn && (
                        <a className="green waves-effect waves-light btn" style={{marginLeft:"10px"}} href="/payment/"><i
                            className="material-icons left">credit_card</i>Pay Now</a>
                    )}
                </div>
            </div>
        )
    }

    useEffect(()=>{
        fetchCurrentSubscription();
    },[]);

    return (
        <>
            {currentSubscription && (params.forceShow || location.pathname.indexOf("payment") === -1)? (
                currentSubscription.status.isSubscriptionActive ? printActiveSubscriptionMessage():printExpiredSubscriptionMessage()
            ):null}
        </>
    )

}