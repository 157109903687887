import React, {useState, useMemo,useContext,useCallback,useEffect} from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import {useHttp} from "../hooks/http.hook";
import {Loader} from "./Loader";
import {useMessage} from "../hooks/message.hook";
import {Link} from "react-router-dom";


export const FilterTypesList = ({_filterTypes, nameChangeCallback,userType}) =>
{
    const [filterTypes, setFilterTypes] = useState(_filterTypes);

    const {loading, request} = useHttp();
    const message = useMessage();
    const [newTypeForm, setNewTypeForm] = useState({
        typeName:"",
    })
    // const [selectedNewColor,setSelectedNewColor] = useState('');
    // const [colors,setColors] = useState('');

    const filterNameChangeHandler = async e =>{
        e.preventDefault();
        let index = e.target.dataset.filterindex;
        let types = [...filterTypes];
        types[index].typeName = e.target.value;

        setFilterTypes(types);
    }

    const filterChangeFinished = async e =>{
        nameChangeCallback(filterTypes);
    }

    useEffect(()=>{
        console.log("updated");
    },[filterTypes]);




    // const AddFilterTypeClickHandler =async e=>{
    //     e.preventDefault();
    //     try {
    //
    //         const data = await  request('/api/broker/filterTypes', 'POST', {...newTypeForm},{Authorization:`Bearer ${token}`});
    //
    //         message(data.message);
    //     }catch (e) {
    //
    //     }
    // }

    // const selectColor = async e =>{
    //     e.preventDefault();
    //
    //     console.log(e.target.value);
    //     setSelectedNewColor(e.target.value);
    //     setNewTypeForm({...newTypeForm,color:e.target.value})
    //
    // }

    // const changeHandler = event =>
    // {
    //     setNewTypeForm({...newTypeForm, [event.target.name]:event.target.value})
    // }

    // const fetchColors = useCallback(async ()=>{
    //     try{
    //         const data = await request("/api/broker/colors","GET",null,{Authorization:`Bearer ${token}`});
    //         setColors(data);
    //     }catch (e) {
    //
    //     }
    // },[token,request]);

    // useEffect(()=>{
    //     fetchColors();
    // },[]);

    // const printColors = () =>{
    //     return(
    //         colors.map((color) => (
    //             <button onClick={selectColor} value={color._id} className={`${color.className} colorPickerBtn btn`} >
    //                 <i className="material-icons" style={{display: (selectedNewColor === color._id)?"block":"none"}}>check</i>
    //             </button>
    //         ))
    //     )
    // }

    return(

        <>
            <div className="row">
                <div className="col s12">
                    <div className="card material-table">

                        {/*<div className="row">*/}
                        {/*    <form className="col s12">*/}
                        {/*        <h5>New filter type details</h5>*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="input-field col s6">*/}
                        {/*                <input id="newFilterTypeName" type="text" name="typeName" value={newTypeForm.typeName} onChange={changeHandler} />*/}
                        {/*                <label htmlFor="newFilterTypeName">New Filter Type Name</label>*/}
                        {/*            </div>*/}
                        {/*            <div className="sol s6">*/}
                        {/*                <label>Color</label>*/}
                        {/*                <br/>*/}

                        {/*                {colors && printColors()}*/}
                        {/*            </div>*/}
                        {/*            /!*<div className="input-field col s6">*!/*/}
                        {/*            /!*    <select*!/*/}
                        {/*            /!*        name="type"*!/*/}
                        {/*            /!*        value={typeForm.type}*!/*/}
                        {/*            /!*        onChange={changeHandler}*!/*/}
                        {/*            /!*    >*!/*/}
                        {/*            /!*        <option value="">Choose your option</option>*!/*/}
                        {/*            /!*        <option value="blacklist">Black list</option>*!/*/}
                        {/*            /!*        <option value="donotcall">Do not call</option>*!/*/}
                        {/*            /!*        <option value="doublebroker">Double Broker</option>*!/*/}
                        {/*            /!*    </select>*!/*/}
                        {/*            /!*    <label>Company filter type</label>*!/*/}
                        {/*            /!*</div>*!/*/}
                        {/*        </div>*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="col s12">*/}
                        {/*                <button className="btn green" style={{marginBottom:10}} onClick={AddFilterTypeClickHandler}>Add Filter Type</button>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </form>*/}
                        {/*</div>*/}

                        <table>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Color</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filterTypes.map((filterType,index) =>{
                                return (
                                    <tr key={filterType._id}>
                                        <td>
                                            <input value={filterType.typeName} data-filterindex={index} disabled={userType==="agent"?"disabled":""} onChange={filterNameChangeHandler} onBlur={filterChangeFinished}/>
                                        </td>
                                        <td>
                                            <div className={`${filterType.color.className} colorPickerBtn`} >
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>

    )
}