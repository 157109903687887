import React, {useState,useContext,useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {Loader} from "../components/Loader";
import {usePayment} from "../hooks/payment.hook";


export const PaymentResultPage = () =>{
    let status = useParams().status;
    const navigate = useNavigate();
    const [orderResult, setOrderResult] = useState({
        resultsReceived:true,
        isPaymentConfirmed:false
    })
    const {GetArcaOrderStatus} = usePayment();


    const pageLoaded = async ()=>{
        if(status === "arca_result")
        {
            const params = new URLSearchParams(window.location.search);
            let order_id = params.get("orderId");

            CheckOrderStatus(order_id);
        }
    }


    useEffect(()=>{
        pageLoaded();
    },[]);


    const CheckOrderStatus = async (order_id)=>
    {
        setOrderResult({...orderResult,resultsReceived:false});

        const paymentOrder = await GetArcaOrderStatus(order_id);

        if(paymentOrder)
        {
            setOrderResult({...orderResult,resultsReceived:true, isPaymentConfirmed: paymentOrder.isPaymentConfirmed});
        }
    }


    const PrintPaymentResultMessage = ()=>
    {
        if(status === "confirmed"){
            return(
                <>
                    <div className="row">
                        <div className="container">
                            <div className="paymentMessageContainer">
                                Payment Received!
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if(status === "failed")
        {
            return(
                <>
                    <div className="row">
                        <div className="container">
                            <div className="paymentMessageContainer">
                                Payment Failed. Please try again<br/>
                                If it doesnt work please contact us by email: contact@sudofreight.com
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if(status === "arca_result")
        {
            return(
                <>
                    <div className="row">
                        <div className="container">
                            <div className="paymentMessageContainer">
                                {!orderResult.resultsReceived ? ( <Loader/> ): (orderResult.isPaymentConfirmed? (
                                    <> Payment Received!</>
                                ):(
                                    <> Payment Failed. Please try again<br/>
                                        If it doesnt work please contact us by email: contact@sudofreight.com</>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )

        }
        else
        {
            return(
                <>
                    <div className="row">
                        <div className="container">
                            <div className="paymentMessageContainer">
                                Something went wrong
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    return(
        <>
            {PrintPaymentResultMessage()}
        </>
    );
}