import React, {useState,useContext} from 'react';
import {AuthContext} from "../context/auth.context";
import {useCallback, useEffect} from "react";
import {useAnalytic} from "../hooks/analytics.hook";
import {Loader} from "../components/Loader";


export const AnalyticsPage = () => {
    const [loginHistory, setLoginHistory] = useState({list:[]});
    const [dateFrom,setDateFrom] = useState("");

    const {getLoginHistory} = useAnalytic();


    const fetchLoginHistory = async  () =>{
        setLoginHistory({});

        let date = new Date(dateFrom);

        let data = await getLoginHistory({dateFrom:date.getTime()});

        setLoginHistory(data);
    }


    const pageLoaded = async ()=>{
        window.M.AutoInit();
        InitDatePickers();
    }


    const InitDatePickers = ()=>{
        let now = new Date();

        let  elem = document.getElementById("dateFromPicker");
        let dateFromInstance = window.M.Datepicker.init(elem,{
            onClose:()=>{
                setDateFrom(dateFromInstance.toString());
            }
        });
        dateFromInstance.setDate(now);
        setDateFrom(dateFromInstance.toString());

    }

    useEffect(()=>{
        pageLoaded();
    },[]);

    return(

        <>
            <div className="row">
                <div className="col s2">
                    <h6>From</h6>
                    <input type="text" className="datepicker" value={dateFrom}  id="dateFromPicker" />
                </div>
                <br/>
                <br/>
                <button className="btn waves-effect waves-light orange"
                              onClick={fetchLoginHistory}
                >
                 Load
                </button>
            </div>

            <div className="section">
                <h5>Login History</h5>  <span>Total: {loginHistory.totalOnlineAmount}</span>
                {loginHistory.list ? (

                    <table>
                        <thead>
                        <tr>
                            <th>Manager Email</th>
                            <th>Total Agents</th>
                            <th>Logged In Agents</th>
                            <th>Manager Login</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loginHistory.list? loginHistory.list.map((loginData,index) =>{

                            return (
                                <tr key={index}>
                                    <td>{loginData.manager}</td>
                                    <td>{loginData.totalUsers}</td>
                                    <td>{loginData.loggedInUsers}</td>
                                    <td>{loginData.managerLoggedIn?"+1":"no"}</td>
                                </tr>
                            )
                        }):""}
                        </tbody>
                    </table>

                ):<Loader/>}

            </div>
            <div className="divider"></div>
        </>

    )
}