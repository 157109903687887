import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/auth.context";
import {Loader} from "../components/Loader";
import {ProfileCard} from "../components/ProfileCard";

export const ProfilePage = () =>{
    const {userId} = useContext(AuthContext);
    const {request,loading} = useHttp();
    const [user,setUser] = useState(null);
    const [directors,setDirectors] = useState([]);
    let _userId = useParams().id;
    if(!_userId)
    {
        _userId = userId;
    }
    //
    // const getUser = useCallback(async ()=>{
    //     try{
    //         const fetched = await request(`/api/user/${_userId}`,"GET",null,true)
    //
    //         setUser(fetched);
    //
    //     }catch (e) {
    //
    //     }
    // }, [_userId,request])

    const getUser = async()=>{
        try{
            const fetched = await request(`/api/user/${_userId}`,"GET",null,true)

            setUser(fetched);

        }catch (e) {

        }
    }

    const fetchDirectorsList = async()=>{
        try{
            const fetched = await request(`/api/user/directorsList`,"GET",null,true)

            setDirectors(fetched);
        }catch (e) {

        }
    }

    useEffect(()=>{getUser();},[_userId]);
    useEffect(()=>{fetchDirectorsList()},[]);

    if(loading || !user)
    {
        return <Loader />
    }

    return(

        <>
            {!loading && user && <ProfileCard user={user} directors={directors}/>}
        </>
    )
}