import React, {useState,useContext} from 'react';
import {usePromoCodes} from "../hooks/promoCodes.hook";
import {useUsers} from "../hooks/user.hook";
import {useCallback, useEffect} from "react";
import {AuthContext} from "../context/auth.context";
import {useMessage} from "../hooks/message.hook";
import {Loader} from "./Loader";



export const RandomPromoCode = () => {
    const [promoEffectTypes, setPromoEffectTypes] = useState([]);
    const [freeRandomPromos, setFreeRandomPromos] = useState(null);
    const [usedRandomPromos, setUsedRandomPromos] = useState(null);
    const [activatedRandomPromos, setActivatedRandomPromos] = useState(null);
    const [freeRandomPromosText, setFreeRandomPromosText] = useState(null);
    const {getPromoEffectTypes, createRandomPublicPromoCodes,getFreeRandomPublicPromos,getUsedRandomPublicPromos,getActivatedRandomPublicPromos, updatePromoListFreeStatus} = usePromoCodes();
    const message = useMessage();
    const [newPromoCodeForm,setNewPromoCodeForm] = useState({
        effectType:-1,
        effectValue:7,
        generateAmount:10
    });

    const fetchData = async () =>{
        setPromoEffectTypes(await getPromoEffectTypes());
        await fetchFreeRandomPromos();
        await fetchUsedRandomPromos();
        await fetchActivatedRandomPromos();
    }

    const fetchFreeRandomPromos = async ()=>{
        const list = await getFreeRandomPublicPromos();

        promoListToText(list);
        setFreeRandomPromos(list);
    }

    const fetchUsedRandomPromos = async ()=>{
        const list = await getUsedRandomPublicPromos();

        setUsedRandomPromos(list);
    }

    const fetchActivatedRandomPromos = async ()=>{
        const list = await getActivatedRandomPublicPromos();
        list.reverse();
        setActivatedRandomPromos(list);
    }

    const promoListToText =(list)=>{
        let text = "";
        for(let i=0;i<list.length;i++)
        {
            text += list[i].name + "\n";
        }

        setFreeRandomPromosText(text);
    }

    const promoListNameToText =(list)=>{
        let text = "";
        for(let i=0;i<list.length;i++)
        {
            text += list[i] + "\n";
        }

        setFreeRandomPromosText(text);
    }
    
    const freeRandomPromosTextChangeHandler = async(e)=>{
        const list = promoTextToList(e.target.value);

        promoListNameToText(list);

        await CompareAndUpdateRemovedItems(list);
    }

    const CompareAndUpdateRemovedItems = async(list) =>
    {
        let removedItemsList = [];
        for(let i=0;i<freeRandomPromos.length;i++)
        {
            let promo = freeRandomPromos[i];
            let index = list.indexOf(promo.name);
            if(index === -1)
            {
                removedItemsList.push(promo);
            }
        }

        message(await updatePromoListFreeStatus(removedItemsList));
    }

    const promoTextToList = (text)=>
    {
        let list = [];
        let rows = text.split("\n");

        for(let i=0;i<rows.length;i++)
        {
            if(rows[i].length>0)
            {
                list.push(rows[i]);
            }
        }

        return list;
    }

    const pageLoaded = async ()=>{
        fetchData();
    }

    useEffect(()=>{
        window.M.AutoInit();
        pageLoaded();
    },[]);


    useEffect(()=>{
        window.M.AutoInit();
    },[promoEffectTypes])


    const changeHandler = event =>
    {
        setNewPromoCodeForm({...newPromoCodeForm, [event.target.name]:event.target.value})
    }

    const newPromoFormNumberValueChangeHandler = (e) =>{
        let value = parseInt(e.target.value);
        if(isNaN(value)) value = 1;
        if(value < 1) value = 1;
        setNewPromoCodeForm({...newPromoCodeForm, [e.target.name]:value})
    }

    const createPromoCodeHandler = async () =>{

        if(newPromoCodeForm.effectType === -1)
        {
            message("Select the promo type");
            return;
        }
        const response = await createRandomPublicPromoCodes(newPromoCodeForm);
        message(response);

        setNewPromoCodeForm({
            effectType:-1,
            effectValue:7,
            generateAmount:10
        });

        await fetchFreeRandomPromos();
    }

    const PrintPromoUser =(promo)=>
    {
        if(promo && promo.usedUsersList.length > 0 && promo.usedUsersList[0].user)
        {
            return promo.usedUsersList[0].user.email + " " + new Date(promo.usedUsersList[0].useDate).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
        }
        else {
            return "invalid user";
        }

    }


    const PrintCreateRandomPromoCodesContainer =()=>
    {
        return (
            <>
                <div className="row">
                    <div className="col s12">
                        <div className="card" style={{padding:"15px"}}>
                            <h5>Create Promo Codes</h5>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Effect</th>
                                        <th>Effect Value</th>
                                        <th>Generate Amount</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="input-field">
                                                <select
                                                    name="effectType"
                                                    value={newPromoCodeForm.effectType}
                                                    onChange={changeHandler}
                                                >
                                                    <option value={-1} defaultValue>Choose Effect type</option>
                                                    {promoEffectTypes.map((type,index) =>{
                                                        return (
                                                            <option key={index} value={type.key}>{type.value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <input type="text" name="effectValue" value={newPromoCodeForm.effectValue} onChange={newPromoFormNumberValueChangeHandler}
                                             />
                                        </td>
                                        <td>
                                            <input type="text" name="generateAmount" value={newPromoCodeForm.generateAmount} onChange={newPromoFormNumberValueChangeHandler}
                                            />
                                        </td>
                                        <td>
                                            <button className="btn waves-effect waves-light orange darken-3" onClick={createPromoCodeHandler}>Create
                                                <i className="material-icons right">add</i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const PrintFreeRandomPromoCodesContainer = ()=>{
        return (
            <>
                <div className="row">

                    <div className="col s2">
                        <h5>Free Promo Codes ({freeRandomPromos?freeRandomPromos.length:0})</h5>
                        {freeRandomPromos? (
                            <textarea style={{height:"500px"}} value={freeRandomPromosText} onChange={freeRandomPromosTextChangeHandler} />
                        ):<Loader/>}
                    </div>
                    <div className="col s4">
                        <h5>Used Promo Codes ({usedRandomPromos?usedRandomPromos.length:0})</h5>
                        {usedRandomPromos? (
                            <div>
                                <ul>
                                    {
                                        usedRandomPromos.length > 0 ? usedRandomPromos.map((promo, index) => {
                                            return (
                                                <li  key={index} className="row" style={{marginBottom: "0px"}}>
                                                    {promo.name}
                                                </li>
                                            )
                                        }) : ""
                                    }
                                </ul>
                            </div>
                        ):<Loader/>}
                    </div>
                    <div className="col s6">
                        <h5>Activated Promo Codes ({activatedRandomPromos?activatedRandomPromos.length:0})</h5>
                        {activatedRandomPromos? (
                            <div>
                                <ul>
                                    {
                                        activatedRandomPromos.length > 0 ? activatedRandomPromos.map((promo, index) => {
                                            return (
                                                <li  key={index} className="row" style={{marginBottom: "0px"}}>
                                                    {promo.name} {PrintPromoUser(promo)}
                                                </li>
                                            )
                                        }) : ""
                                    }
                                </ul>
                            </div>
                        ):<Loader/>}
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {PrintCreateRandomPromoCodesContainer()}<br/>
            {PrintFreeRandomPromoCodesContainer()}<br/>
        </>
    )
}