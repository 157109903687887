import React, {useEffect, useState,useContext} from 'react';
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/auth.context";
import {useMessage} from "../hooks/message.hook";
import {useNavigate} from "react-router-dom";


export  const ProfileCard = ({user,directors}) =>
{
    const navigate = useNavigate();

    const [newEmail, setNewEmail] = useState('');
    const [newName, setNewName] = useState('');
    const [isUserTypeChanged, setIsUserTypeChanged] = useState(false);
    const [newParent, setNewParent] = useState(null);
    const [newParentChanged, setNewParentChanged] = useState(false);
    const [userNewType, setUserNewType] = useState('');
    const [childUsers, setChildUsers] = useState([]);

    const [newAgentLimit, setNewAgentLimit] = useState(0);
    const [newPaymentDate, setNewPaymentDate] = useState('');
    const [newTrialPeriodEndDate, setNewTrialPeriodEndDate] = useState('');
    const [newPassword,setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
    const message = useMessage();
    const {loading, request} = useHttp();
    const {userType} = useContext(AuthContext);

    const updateNameHandler = async e =>{
        e.preventDefault();

        if(newName === "")
        {
            message("Name is Empty");
            return;
        }

        const data = await  request(`/api/user/update/name/${user._id}`, 'PUT', {newName},true);
        message(data.message);

        window.location.reload();
    }

    const saveUserTypeHandler = async e =>{
        e.preventDefault();
        setIsUserTypeChanged(false);

        try {
            const data = await  request(`/api/user/update/userType/${user._id}`, 'PUT', {userNewType:userNewType},true);
            message(data.message);
            window.location.reload();
        }catch (e) {
            message(e.message);
        }
    }



    const updateAgentLimit = async e =>{
        e.preventDefault();

        const data = await  request(`/api/user/update/agentsLimit/${user._id}`, 'PUT', {newAgentLimit},true);
        message(data.message);

        window.location.reload();
    }

    const updateEmailHandler = async e =>{
        e.preventDefault();

        if(newEmail === "")
        {
            message("Email is Empty");
            return;
        }

        const data = await  request(`/api/user/update/email/${user._id}`, 'PUT', {newEmail},true);
        message(data.message);

        window.location.reload();
    }



    const updatePasswordHandler = async e =>{
        e.preventDefault();

        if(newPassword === "" || newPasswordRepeat === "")
        {
            message("Password is empty");
            return null;
        }

        try {
            if(newPassword !== newPasswordRepeat)
            {
                message("Passwords don't match");
                return null;
            }

            const data = await  request(`/api/user/update/password/${user._id}`, 'PUT', {newPassword},true);

            message(data.message);
        }catch (e) {
            message(e.message);
        }
    }


    const startTrialPeriodHandler = async e =>{
        e.preventDefault();
        await ToggleTrial(true);
    }

    const stopTrialPeriodHandler = async e =>{
        e.preventDefault();
        await ToggleTrial(false);
    }

    const resendEmailVerificationHandler = async e =>{
        e.preventDefault();
        try {
            const data = await  request(`/api/user/resendEmailVerification/${user._id}`, 'PUT', {},true);
            message(data.message);
        }catch (e) {
            message(e.message);
        }
    }


    async function ToggleTrial(activate)
    {
        try {
            const data = await  request(`/api/user/update/trialPeriodState/${user._id}`, 'PUT', {isActive:activate},true);

            message(data.message);
            window.location.reload();
        }catch (e) {
            message(e.message);
        }
    }

    const newPaymentDateChange = () =>{
        let dom = document.getElementById("newPaymentDatepicker");

        setNewPaymentDate(dom.value);
    }

    const trialPeriodEndChange = () =>{
        let dom = document.getElementById("trialPeriodDatePicker");

        setNewTrialPeriodEndDate(dom.value);
    }


    const updateTrialPeriodHandler = async e =>
    {
        try {
            let dateString = new Date(newTrialPeriodEndDate).toISOString();

            const data = await  request(`/api/user/update/trialPeriodEndDate/${user._id}`, 'PUT', {newDate:dateString},true);

            message(data.message);
            //window.location.reload();
        }catch (e) {
            message(e.message);
        }
    }

    useEffect(()=>{

        var elems = document.querySelectorAll('.collapsible');
        var instances = window.M.Collapsible.init(elems);

        window.M.Datepicker.init(document.getElementById("newPaymentDatepicker"),{
            onClose:()=>{
                newPaymentDateChange();
            }
        });
        window.M.Datepicker.init(document.getElementById("trialPeriodDatePicker"),{
            onClose:()=>{
                trialPeriodEndChange();
            }
        });

        window.M.updateTextFields();

        if(user.userType === 'director' || user.userType === 'manager')
        {
            fetchChildUsers();
        }

        setUserNewType(user.userType);
    },[]);

    const fetchChildUsers = async () =>{
        try{
            const data = await request(`/api/user/childUsers/${user._id}`,"GET",null,true)

            setChildUsers(data);
        }catch (e) {

        }
    }


    const openUser = (userId) => {
        navigate(`/profile/${userId}`);
    }

    const printChildUsers = () =>{
        if(childUsers.length === 0) return "No Child Users";

        return (
            <table>
                <tbody>
                {childUsers.map(function(childUser, i){
                    return (
                        <tr>
                            <td>{childUser.name}</td>
                            <td>{childUser.email}</td>
                            <td>
                                <button className="btn waves-effect waves-light orange"
                                        onClick={() => openUser(childUser._id)}
                                >
                                    <i className="material-icons">person</i>
                                </button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>

            </table>

        )
    }

    const saveNewParentHandler = async() =>{
        try {
            const data = await  request(`/api/user/update/parent/${user._id}`, 'PUT', {newParent:newParent},true);
            message(data.message);
            window.location.reload();
        }catch (e) {
            message(e.message);
        }
    }

    const printParentUser = () =>{

        return (
            <>
                <div className="row">
                    <h6>Parent</h6>
                    {user.parent? (
                        <table>
                            <tbody>
                            <tr>
                                <td>{user.parent.name}</td>
                                <td>{user.parent.email}</td>
                                <td>
                                    <button className="btn waves-effect waves-light orange"
                                            onClick={() => openUser(user.parent._id)}
                                    >
                                        <i className="material-icons">person</i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>

                        </table>
                    ):"No Parent User"}
                </div>

                {user.userType === "manager" ? (
                    <div className="row">
                        <h6>Update Parent</h6>
                        <select className="browser-default" value={newParent} onChange={(e)=>{ setNewParent(e.target.value);setNewParentChanged(true) }}>
                            <option value={null}>No Director</option>
                            {directors.map(function(director, i){
                                return (
                                    <option value={director._id}>{director.name} --- {director.email}</option>
                                );
                            })}
                        </select>
                        <button className="btn waves-effect waves-light orange" disabled={!newParentChanged}
                                onClick={saveNewParentHandler}
                        >
                            Save Director
                        </button>
                    </div>
                ):""}

            </>


        )
    }

    return(

        <>
            <h4>Profile: {" "}{user.name} {" , "} {user.email}</h4>


            {userType === "admin" ? (
                <>
                    <div className="row">
                        <div className="col s12 m5">
                            <h5>Payment/Trial Period</h5>
                            <span>Trial Period:  {user.isTrialPeriod?"On":"Off"} </span>
                            {user.isTrialPeriod?
                                <button className="btn red " style={{marginLeft:'1rem'}} onClick={stopTrialPeriodHandler}>Stop Trial</button>
                                :
                                <button className="btn green " onClick={startTrialPeriodHandler}>Start Trial</button>
                            }
                            <br/>
                            {user.isTrialPeriod?
                                <>
                                    <span>Trial Period Ends: </span>
                                    <span>{new Date(user.subscriptionEndDate).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}</span><br/>
                                    <div>
                                        <br/>
                                        <h6>Update Trial Period End Date</h6>
                                        <input type="text" className="datepicker"  name="trialPeriodDatePicker"  id="trialPeriodDatePicker" onChange={trialPeriodEndChange}/>

                                        <button className="btn orange darken-3 " onClick={updateTrialPeriodHandler}>Save Trial Period End Date</button>
                                    </div>
                                </>:
                                <>

                                </>
                            }
                        </div>
                    </div>
                    <div>

                    </div>
                </>

            ):
                <>

                </>
            }
            <br/>

            {!user.isEmailVerified?
                <div>
                    <button className="btn yellow darken-4" style={{marginTop:'1rem'}} onClick={resendEmailVerificationHandler}>Resend email verification</button>
                </div>
                :null
            }

            <div className="row">
                <div style={{marginTop:"1rem"}} className="col s12">
                    <span>Next Payment Date : {new Date(user.subscriptionEndDate).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}</span>
                </div>
            </div>

            <ul className="collapsible">
                {userType === "admin" ? (
                    <li>
                        <div className="collapsible-header"><i className="material-icons">assignment_ind</i>Type: {user.userType} </div>
                        <div className="collapsible-body">
                            <div className="row">
                                <div className="col s6">
                                    <div className="row">
                                        <h6>User Type</h6>
                                        <select className="browser-default col s3"
                                                value={userNewType}
                                                onChange={e => {
                                                    setUserNewType(e.target.value);
                                                    setIsUserTypeChanged(e.target.value !== user.userType);
                                                }}
                                        >
                                            <option value="director">Director</option>
                                            <option value="manager">Manager</option>
                                            <option value="marketing">Marketing</option>
                                            <option value="agent">Agent</option>
                                        </select>
                                        <button className="btn yellow darken-4" disabled={!isUserTypeChanged}
                                                onClick={saveUserTypeHandler}>Save Type</button>
                                    </div>

                                    {(userNewType !== "director") ? (
                                        <div className="row">
                                            {printParentUser()}
                                        </div>

                                    ):""}

                                    {(userNewType === "director" || userNewType === "manager") ? (
                                        <div className="row">
                                            <h6>Child Users</h6>
                                            {printChildUsers()}
                                        </div>
                                    ):""}


                                </div>
                            </div>
                        </div>
                    </li>
                ):""}
                <li>
                    <div className="collapsible-header"><i className="material-icons">account_circle</i>Name: {user.name} </div>
                    <div className="collapsible-body">
                        <div className="row">
                            <form className="col s12">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <input id="name" type="text"  value={newName} onChange={e => setNewName(e.target.value)}/>
                                        <label htmlFor="name">New Name</label>
                                        <span className="helper-text" data-error="wrong" data-success="right">Type new Name</span>
                                    </div>
                                    <button className="btn yellow darken-4"
                                            onClick={updateNameHandler}>Update Name</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="collapsible-header"><i className="material-icons">email</i>{user.email} </div>
                    <div className="collapsible-body">
                        <div className="row">
                            <form className="col s12">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <input id="email" type="email" className="validate" value={newEmail} onChange={e => setNewEmail(e.target.value)}/>
                                            <label htmlFor="email">New Email</label>
                                            <span className="helper-text" data-error="wrong" data-success="right">Type new email address</span>
                                    </div>
                                    <button className="btn yellow darken-4"
                                            onClick={updateEmailHandler}>Update Email</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="collapsible-header"><i className="material-icons">lock</i>Update Password</div>
                    <div className="collapsible-body">
                        <div className="input-field col s12">
                            <input id="password" type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                            <label htmlFor="password">New password</label>
                        </div>
                        <div className="input-field col s12">
                            <input id="password_repeat" type="password" value={newPasswordRepeat} onChange={e => setNewPasswordRepeat(e.target.value)} />
                            <label htmlFor="password_repeat">Repeat new password</label>
                        </div>
                        <button className="btn yellow darken-4"
                                onClick={updatePasswordHandler}>Update Password</button>
                    </div>
                </li>
                {/*<li>*/}
                {/*    <div className="collapsible-header"><i className="material-icons">settings</i>Update Settings</div>*/}
                {/*    <div className="collapsible-body"><span>Coming soon.</span></div>*/}
                {/*</li>*/}
            </ul>

        </>

    )
}