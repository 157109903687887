import React, {useState, useMemo, useEffect} from 'react';
import {useHttp} from "../hooks/http.hook";
// import {useNavigate} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useTable, useSortBy, usePagination, useGlobalFilter, useFilters } from "react-table";

import "../css/dataTable.css";
import {ColumnFitler} from "./ColumnFilter";

export const UsersList = ({loggedInUserType, users,callbackFunction}) =>
{
    const {loading, request} = useHttp();
    const navigate = useNavigate();
    const [userToBeDeleted, setUserToBeDeleted] = useState({userId:null, name:""});


    const getAgentsAmount = () =>
    {
        let agents = 0;
        for (let i=0;i<users.length;i++)
        {
            if(users[i].userType === "agent")
            {
                agents +=1;
            }
        }

        return agents;
    }

    const getManagersAmount = () =>
    {
        let managers = 0;
        for (let i=0;i<users.length;i++)
        {
            if(users[i].userType === "manager")
            {
                managers +=1;
            }
        }

        return managers;
    }

    const subscriptionColumn = {
        Header: "Subscription End",
        accessor: "subscription",
        sortType: (a, b) => {

            if(!a.original.subscriptionEndDate || isNaN(new Date(a.original.subscriptionEndDate).getTime())) a.original.subscriptionEndDate = "0";
            if(!b.original.subscriptionEndDate || isNaN(new Date(b.original.subscriptionEndDate).getTime())) b.original.subscriptionEndDate = "0";
            return new Date(a.original.subscriptionEndDate) - new Date(b.original.subscriptionEndDate);
        },
        Cell: cellProps => {

            if(!cellProps.row.original.isEmailVerified)
            {
                return ("Email verification pending");
            }
            else
            {
                if(cellProps.row.original.subscriptionEndDate && cellProps.row.original.subscriptionEndDate!=="0")
                {
                    return (
                        new Date(cellProps.row.original.subscriptionEndDate).toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })
                    )
                }
                else
                {
                    return ("No End Date");
                }

            }



        }
    }

    const registrationColumn = {
        Header: "Register Date",
        accessor: "registrationDate",
        sortType: (a, b) => {
            return new Date(a.original.date) - new Date(b.original.date);
        },
        Cell: cellProps => {

            return new Date(cellProps.row.original.date).toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })

        }
    }


    const actionColumn = {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
            return <CustomActionCell {...cellProps} />;
        },

    }

    const adminColumns = [
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Type",
            accessor: "userType",
             Filter:SelectColumnFilter
        },
        {
            Header: "Parent",
            accessor: "parent.email",
            Filter:SelectColumnFilter
        },
        {
            Header: "Country",
            accessor: "country"
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        registrationColumn,
        subscriptionColumn,
        actionColumn

    ];

    const managerColumns = [
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Name",
            accessor: "name"
        },
        subscriptionColumn,
        actionColumn
    ];

    const columnProps =loggedInUserType === "admin"?adminColumns:managerColumns;

    const columns = useMemo(
        () => columnProps,
        []
    );

    const data = useMemo(
        () => users,
        [users]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        prepareRow,
        state,
        setGlobalFilter
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0},
        autoResetPage: false
    },useFilters, useGlobalFilter,useSortBy,usePagination);

    const {globalFilter} = state;

    const openUser = (userLink) => {
        navigate(userLink);
    }

    function CustomActionCell(cellProps) {
        return (
            <>
                <div className="row " style={{marginBottom:0}}>
                    <div className="col s12 m6">
                        <button className="btn waves-effect waves-light orange"
                                onClick={() => openUser(`/profile/${cellProps.row.original._id}`)}
                        >
                            <i className="material-icons">person</i>
                        </button>
                        {/*<Link to={`/profile/${cellProps.row.original._id}`}>Open</Link>*/}
                    </div>
                    <div className="col s12 m6">
                        <button className="btn waves-effect waves-light  red lighten-1"
                                onClick={() => DeleteRowOpenPopupHandler(cellProps.row.original._id,cellProps.row.original.name)}
                        >
                            <i className="material-icons">delete</i>
                        </button>                    </div>
                </div>


            </>
        );
    }



    useEffect(()=>{
        window.M.AutoInit();
    },[]);

    function SelectColumnFilter({
            column: { filterValue, setFilter, preFilteredRows, id },
        }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])

        // Render a multi-select box
        return (
            <select
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                className="browser-default"
            >
                <option value="">All</option>
                {options.map((option, i) => (
                    <option key={i} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        )
    }

    const DeleteRowOpenPopupHandler = (id,name)=>{

        setUserToBeDeleted({
            userId: id,
            name:name
        })

        let modal = window.document.getElementById('delete_popup');
        window.M.Modal.init(modal,{});
        let instance = window.M.Modal.getInstance(modal);
        instance.open();
    }

    const DeleteAgreeHandler = async  (e)=>{
        e.preventDefault();

        await deleteEntryHandler(userToBeDeleted.userId)
    }


    const deleteEntryHandler = async (entryId) =>{
        try {
            await request("/api/user","DELETE",{userId:entryId},true);

            callbackFunction();
        }
        catch (e) {

        }
    }


    if(!users.length)
    {
        return (
            <p className="center">No Users</p>
        )
    }

    return(

        <>
            <div className="card material-table">

                <div className="table-header">
                    <span className="table-title">Users List</span>
                    <div >
                        {loggedInUserType === "admin"? (
                            <span style={{marginLeft:"1rem"}}>Total Managers: {getManagersAmount()}</span>
                        ):""}
                        <span style={{marginLeft:"1rem"}}>Total Agents: {getAgentsAmount()}</span>
                    </div>

                    <div className="actions">
                        <input value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}/>
                        <span><i className="material-icons">search</i></span>
                    </div>
                </div>

                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column,i) => (
                                <th key={i}>
                                    <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        <span>{column.isSorted? (column.isSortedDesc? '↓':'↑'):''}</span>
                                    </div>

                                    <div>
                                        {(column.canFilter && column.Filter) ? column.render("Filter"):null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={i} >
                                {row.cells.map((cell) => {
                                    return <td  {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                <div className="pagination table-footer">

                    <label>
                        Page {' '}
                        <label>
                            {pageIndex + 1} of {pageOptions.length}
                        </label>{' '}
                    </label>
                    {/*<span>*/}
                    {/*| Go to page:{' '}*/}
                    {/*    <input*/}
                    {/*        type="number"*/}
                    {/*        defaultValue={pageIndex + 1}*/}
                    {/*        onChange={e => {*/}
                    {/*            const page = e.target.value ? Number(e.target.value) - 1 : 0*/}
                    {/*            gotoPage(page)*/}
                    {/*        }}*/}
                    {/*        style={{ width: '100px' }}*/}
                    {/*    />*/}
                    {/*</span>{' '}*/}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    <ul className="material-pagination">
                        <li className="paginate_button">
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <i className="material-icons">chevron_left</i>
                            </button>
                        </li>
                        <li className="paginate_button">
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                <i className="material-icons">chevron_right</i>
                            </button>
                        </li>
                    </ul>
                    {/*<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>*/}
                    {/*    {'<<'}*/}
                    {/*</button>{' '}*/}
                    {' '}

                    {/*<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>*/}
                    {/*    {'>>'}*/}
                    {/*</button>{' '}*/}
                </div>
            </div>


            <div id="delete_popup" className="modal small-modal">
                <div className="modal-content">
                    <h5>Delete Confirm</h5>
                    <span>Are you sure you want to delete <br /> {userToBeDeleted.name}?</span>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12"  style={{marginBottom:10}}>
                            <button className="btn red modal-close" onClick={DeleteAgreeHandler}>Delete</button>
                            <button className="btn grey modal-close"  style={{marginLeft:10}}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}