import React, {useContext} from 'react';
import {NavLink, useNavigate,useLocation} from 'react-router-dom';
import {AuthContext} from "../context/auth.context";

export const Navbar= () =>{
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useContext(AuthContext);
    const logoutHandler = event =>{
        event.preventDefault();
        auth.logout();
        navigate("/");
    }

    const PrintNavLinks = () =>
    {

        return (
            <>
                {auth.userType  !== "admin"? (
                    <>
                        <li className={location.pathname === "/profile/"?"active":""}><NavLink to="/profile/">Profile</NavLink></li>
                        {auth.userType  !== "marketing"?(
                            <li className={location.pathname === "/payment/"?"active":""}><NavLink to="/payment/">Payment</NavLink></li>
                        ):null }
                    </>
                ):null}

                {auth.userType  === "marketing" || auth.userType  === "admin"?(
                    <li className={location.pathname === "/promoCodes/"?"active":""}><NavLink to="/promoCodes/">Promo Codes</NavLink></li>
                ):null }

                {auth.userType  === "manager" && auth.isSubscriptionActive? (
                    <li className={location.pathname === "/users"?"active":""}><NavLink to="/users">My Team</NavLink></li>
                ):null}

                {auth.userType  === "admin"? (
                    <>
                        <li className={location.pathname === "/analytics"?"active":""}><NavLink to="/analytics">Analytics</NavLink></li>
                        <li className={location.pathname === "/adminPayments"?"active":""}><NavLink to="/adminPayments">Payments</NavLink></li>
                        <li className={location.pathname === "/users"?"active":""}><NavLink to="/users">All Users</NavLink></li>
                    </>
                ):null}

                {(auth.userType  === "manager" || auth.userType === "agent") && auth.isSubscriptionActive? (
                    <>
                        <li className={location.pathname === "/companyFilters"?"active":""}><NavLink to="/companyFilters">Broker Filtering</NavLink></li>
                        <li className={location.pathname === "/filters"?"active":""}><NavLink to="/filters">My Filters</NavLink></li>
                    </>
                    ):null}

                {auth.userType  !== "admin" && auth.userType  !== "marketing" && auth.isSubscriptionActive? (
                    <>
                        <li className={location.pathname === "/callHistory"?"active":""}><NavLink to="/callHistory">Contact History</NavLink></li>
                    </>
                ):null}

                {(auth.userType  === "manager" || auth.userType === "agent") && auth.isSubscriptionActive? (
                    <>
                        {/*<li className={location.pathname === "/activeLoads"?"active":""}><NavLink to="/activeLoads">Active Loads</NavLink></li>*/}
                        <li className={location.pathname === "/lines"?"active":""}><NavLink to="/lines">Lines</NavLink></li>
                    </>
                ):null}

                <li><a href="/" onClick={logoutHandler}>Logout</a></li>
            </>
        )
    }

    return (
        <>
            <nav>
                <div className="nav-wrapper grey darken-3" style={{padding:"0 2rem"}}>
                    <a href="#!" className="brand-logo">
                        <img src={require('../img/logo192.png')}/>
                        <h6 className="header_email">{auth.userEmail}</h6>
                    </a>
                    <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i
                        className="material-icons">menu</i></a>
                    <ul className="right hide-on-med-and-down">
                        {PrintNavLinks()}
                    </ul>
                </div>
            </nav>

            <ul className="sidenav" id="mobile-demo">
                {PrintNavLinks()}
            </ul>

        </>

    )
}