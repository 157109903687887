import {useState, useCallback} from 'react';
import {useHttp} from "./http.hook";


export const useFilterTypes = () => {
    const {loading, request} = useHttp();

    const getFilters = useCallback(async ()=>{
        try{
            const data = await request("/api/user/filterOverrides","GET",null,true);
            data.sort((a, b) => (a.index > b.index) ? 1 : -1)
            return data;
        }catch (e) {

        }
    },[request]);


    return  {getFilters}
}