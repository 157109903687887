import React from 'react';
import {
	Route,
	Routes,
	Navigate

} from "react-router-dom";
import  {LinksPage} from './pages/LinksPage';
import  {CreatePage} from './pages/CreatePage';
import  {DetailPage} from './pages/DetailPage';
import  {AuthPage} from "./pages/AuthPage";
import  {PasswordRecoveryPage} from "./pages/PasswordRecoveryPage";
import {UsersPage} from "./pages/UsersPage";
import {BrokerFilterPage} from "./pages/BrokerFilterPage";
import {ProfilePage} from "./pages/ProfilePage";
import {CreateUserPage} from "./pages/CreateUserPage";
import {FiltersPage} from "./pages/FiltersPage";
import {EmailVerifyPage} from "./pages/EmailVerifyPage";
import {CallHistoryPage} from "./pages/CallHistoryPage";
import {MCPage} from "./pages/MCPage";
import {ActiveLoadsPage} from "./pages/ActiveLoadsPage";
import {PaymentPage} from "./pages/PaymentPage";
import {AdminPayments} from "./pages/AdminPayments";
import {PaymentResultPage} from "./pages/PaymentResultPage";
import {PromoCodesPage} from "./pages/PromoCodesPage";
import {AnalyticsPage} from "./pages/AnalyticsPage";

export const useRoutes = (isAuthenticated, userType,isSubscriptionActive) =>{

	const globalRoutes = () =>{
		return(
			<>
				<Route path="/emailVerify/:hash" element={<EmailVerifyPage />} />
				<Route path="/passwordRecovery/:hash" element={<PasswordRecoveryPage />} />
				<Route path="/paymentResult/:status" element={<PaymentResultPage />} />
			</>
		)
	}

	const adminRoutes = ()=>{
		return(
			<Routes>
				{globalRoutes()}
				<Route path="/links" exact element={<LinksPage />} />
				<Route path="/create" exact element={<CreatePage />} />
				<Route path="/detail/:id" element={<DetailPage />} />
				<Route path="/profile/:id" element={<ProfilePage />} />
				<Route path="/profile/" element={<ProfilePage />} />
				<Route path="/users" exact element={<UsersPage />} />
				<Route path="/createUser" exact element={<CreateUserPage />} />
				<Route path="/companyFilters/:action/:name/:contact"  element={<BrokerFilterPage />} />
				<Route path="/companyFilters/:action/:name"  element={<BrokerFilterPage />} />
				<Route path="/companyFilters" exact element={<BrokerFilterPage />} />
				<Route path="/filters" exact element={<FiltersPage />} />
				<Route path="/callHistory" exact element={<CallHistoryPage />} />
				<Route path="/activeLoads" exact element={<ActiveLoadsPage />} />
				<Route path="/payment" exact element={<PaymentPage />} />
				<Route path="/lines" exact element={<MCPage />} />
				<Route path="/promoCodes/" exact element={<PromoCodesPage />} />
				<Route path="/adminPayments/" exact element={<AdminPayments />} />
				<Route path="/analytics/" exact element={<AnalyticsPage />} />
				<Route
					path="*"
					element={<Navigate to="/users" replace />}
				/>
			</Routes>
		)
	}

	const managerRoutes = () =>{
		return(
			<Routes>
				{globalRoutes()}
				<Route path="/links" exact element={<LinksPage />} />
				<Route path="/create" exact element={<CreatePage />} />
				<Route path="/detail/:id" element={<DetailPage />} />
				<Route path="/profile/:id" element={<ProfilePage />} />
				<Route path="/profile/" element={<ProfilePage />} />
				<Route path="/users" exact element={<UsersPage />} />
				<Route path="/createUser" exact element={<CreateUserPage />} />
				<Route path="/companyFilters/:action/:name/:contact"  element={<BrokerFilterPage />} />
				<Route path="/companyFilters/:action/:name"  element={<BrokerFilterPage />} />
				<Route path="/companyFilters" exact element={<BrokerFilterPage />} />
				<Route path="/filters" exact element={<FiltersPage />} />
				<Route path="/callHistory" exact element={<CallHistoryPage />} />
				<Route path="/activeLoads" exact element={<ActiveLoadsPage />} />
				<Route path="/payment/" exact element={<PaymentPage />} />
				<Route path="/lines" exact element={<MCPage />} />
				<Route
					path="*"
					element={<Navigate to="/users" replace />}
				/>
			</Routes>
		)
	}

	const agentRoutes = () =>{
		return(
			<Routes>
				{globalRoutes()}
				<Route path="/profile/:id" element={<ProfilePage />} />
				<Route path="/profile/" element={<ProfilePage />} />
				<Route path="/companyFilters/:action/:name/:contact"  element={<BrokerFilterPage />} />
				<Route path="/companyFilters/:action/:name"  element={<BrokerFilterPage />} />
				<Route path="/companyFilters" exact element={<BrokerFilterPage />} />
				<Route path="/filters" exact element={<FiltersPage />} />
				<Route path="/callHistory" exact element={<CallHistoryPage />} />
				<Route path="/lines" exact element={<MCPage />} />
				<Route path="/activeLoads" exact element={<ActiveLoadsPage />} />
				<Route path="/payment/" exact element={<PaymentPage />} />
				<Route
					path="*"
					element={<Navigate to="/companyFilters" replace />}
				/>
			</Routes>
		)
	}

	const directorRoutes = () =>{
		return(
			<Routes>
				{globalRoutes()}
				<Route path="/profile/:id" element={<ProfilePage />} />
				<Route path="/profile/" element={<ProfilePage />} />
				<Route path="/callHistory/" exact element={<CallHistoryPage />} />
				<Route path="/payment/" exact element={<PaymentPage />} />
				<Route
					path="*"
					element={<Navigate to="/callHistory" replace />}
				/>
			</Routes>
		)
	}

	const marketingRoutes = () =>{
		return(
			<Routes>
				{globalRoutes()}
				<Route path="/profile/:id" element={<ProfilePage />} />
				<Route path="/profile/" element={<ProfilePage />} />
				<Route path="/promoCodes/" exact element={<PromoCodesPage />} />
				<Route
					path="*"
					element={<Navigate to="/promoCodes/" replace />}
				/>
			</Routes>
		)
	}

	const onlyPaymentRoutes = () =>{
		return(
			<Routes>
				{globalRoutes()}
				<Route path="/profile/:id" element={<ProfilePage />} />
				<Route path="/profile/" element={<ProfilePage />} />
				<Route path="/payment/" exact element={<PaymentPage />} />
				<Route
					path="*"
					element={<Navigate to="/payment/" replace />}
				/>
			</Routes>
		)
	}

	if(isAuthenticated)
	{
		if(isSubscriptionActive)
		{
			if(userType === "admin")
			{
				{return adminRoutes()}
			}
			else if(userType === "manager")
			{
				{return managerRoutes()}
			}
			else if(userType === "agent")
			{
				{return agentRoutes()}
			}
			else if(userType === "director")
			{
				{return directorRoutes()}
			}
			else if(userType === "marketing")
			{
				{return marketingRoutes()}
			}
		}
		else
		{
			if(userType === "admin")
			{
				{return adminRoutes()}
			}
			else if(userType === "manager")
			{
				{return onlyPaymentRoutes()}
			}
			else if(userType === "agent")
			{
				{return onlyPaymentRoutes()}
			}
			else if(userType === "director")
			{
				{return onlyPaymentRoutes()}
			}
		}
	}

	return (
		<Routes>
			{globalRoutes()}
			<Route path="/" exact element={<AuthPage />} />
			<Route
				path="*"
				element={<Navigate to="/" replace />}
			/>
		</Routes>
	)
}