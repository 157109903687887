import React, {useState,useContext} from 'react';
import {useHttp} from "../hooks/http.hook";
import {useCallback, useEffect} from "react";
import {Loader} from "../components/Loader";
import {CallHistoryList} from "../components/CallHistoryList";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";
import {useMcSettings} from "../hooks/mcSettings.hook";
import {useUsers} from "../hooks/user.hook";


import 'materialize-css';

export const CallHistoryPage = () =>{
    const [history,setHistory] = useState([]);
    const [users, setUsers] = useState([]);
    const [managers, setManagers] = useState([]);
    const [mcSettings, setMcSettings] = useState([]);
    const {getMcSettings,getMcSettingsByParentId} = useMcSettings();
    const {getDirectorChildManagers,getManagerChildUsers} = useUsers();
    const [managerInfoState, setManagerInfoState] = useState({
        requested:false,
        received:false
    });




    const [dateFrom,setDateFrom] = useState("");
    const [dateTo,setDateTo] = useState("");

    const {userType,userId,userName, userEmail} = useContext(AuthContext);

    const [searchForm,setSearchForm] = useState({
        usersList:[],
        mcList:[],
        phoneLogs:true,
        emailLogs:true,
        managerId:"me"
    })

    const fetchMCSettings = async ()=>{
        try{
            const data = await getMcSettings();
            setMcSettings(data);

            return data;
        }catch (e) {

        }
    };

    const fetchUsers = async ()=>{
        return await getManagerChildUsers(null,false);
    }



    const pageLoaded = async ()=>{
        console.log("pageLoaded");

        window.M.AutoInit();
        InitDatePickers();

        window.M.updateTextFields();

        if(userType === "director")
        {
            setManagers(await getDirectorChildManagers());
        }
        else if(userType === "manager") {
            let mcSettings = await fetchMCSettings();

            let users = await fetchUsers();
            users.push({
                _id:userId,
                name:userName,
                email:userEmail
            });
            setUsers(users);

            SetMcAndSelectedUsersAll(mcSettings,users,"me");
        }
        else if(userType === "agent")
        {
            let mcSettings = await fetchMCSettings();

            SetMcAndSelectedUsersAll(mcSettings,[],"me");
        }

    }

    const SetMcAndSelectedUsersAll = useCallback((mcSettings,users,managerId)=>{

        console.log("SetMcAndSelectedUsersAll");
        let selectedUsers = [];
        for(let i=0;i<users.length;i++)
        {
            selectedUsers.push(users[i]._id);
        }
        console.log(selectedUsers);

        let selectedMC = [];
        for(let i=0;i<mcSettings.length;i++)
        {
            let mcID = mcSettings[i]._id;
            if( mcSettings[i].parentMc){
                mcID = mcSettings[i].parentMc._id;
            }
            selectedMC.push(mcID);
        }

        setSearchForm({...searchForm,usersList: selectedUsers,mcList:selectedMC,managerId: managerId});
    },[]);

    const managerSelectChangeHandler = async(e)=>{
        const value = e.target.value;
        setSearchForm({...searchForm,managerId:value});

        setManagerInfoState({...managerInfoState,requested: true,received:false});

        if(value === "me") return;

        const mcSettings = await getMcSettingsByParentId(value);
        setMcSettings(mcSettings);

        const users = await getManagerChildUsers(value,false)
        users.push({
            _id:value,
            name:managers.find(m=>m._id===value).name,
            email:managers.find(m=>m._id===value).email,
        });
        setUsers(users);
        console.log("managerSelectChangeHandler");

        SetMcAndSelectedUsersAll(mcSettings,users,value);
        setManagerInfoState({...managerInfoState,requested: false,received:true});

    }

    const AllUsersCheckboxClickHandler = e =>{
        if(searchForm.usersList.length === users.length)
        {
            setSearchForm({...searchForm, usersList:[]})
        }
        else
        {
            let selectedUsers = [];
            for(let i=0;i<users.length;i++)
            {
                selectedUsers.push(users[i]._id);
            }
            setSearchForm({...searchForm, usersList:selectedUsers})
        }
    }

    const newSearchSelectedUserChangeHandler = e =>{

        const userId = e.target.dataset.user;

        let selectedUsers = [...searchForm.usersList];

        let index = selectedUsers.indexOf(userId);
        if(index === -1)
        {
            selectedUsers.push(userId);
        }
        else
        {
            selectedUsers.splice(index, 1);
        }

        setSearchForm({...searchForm, usersList:selectedUsers})

    }

    const AllMCCheckboxClickHandler = e =>{
        if(searchForm.mcList.length === mcSettings.length)
        {
            setSearchForm({...searchForm, mcList:[]})
        }
        else
        {
            let selectedMC = [];
            for(let i=0;i<mcSettings.length;i++)
            {
                let mcID = mcSettings[i]._id;
                if( mcSettings[i].parentMc){
                    mcID = mcSettings[i].parentMc._id;
                }
                selectedMC.push(mcID);
            }

            setSearchForm({...searchForm, mcList:selectedMC})
        }
    }


    const newSearchMcSelectboxChanged = e =>{

        const mcID = e.target.dataset.mcid;

        let mcList = [...searchForm.mcList];

        let index = mcList.indexOf(mcID);
        if(index === -1)
        {
            mcList.push(mcID);
        }
        else
        {
            mcList.splice(index, 1);
        }

        setSearchForm({...searchForm, mcList:mcList})
    }

    const InitDatePickers = ()=>{
        let now = new Date();
        let yesterday = new Date();

        yesterday.setDate(yesterday.getDate() - 1);

        let elem = document.getElementById("dateFromPicker");
        let dateFromInstance = window.M.Datepicker.init(elem,{
            onClose:()=>{
                setDateFrom(dateFromInstance.toString());
            }
        });
        dateFromInstance.setDate(yesterday);
        setDateFrom(dateFromInstance.toString());



        elem = document.getElementById("dateToPicker");
        let dateToInstance = window.M.Datepicker.init(elem,{
            onClose:()=>{
                setDateTo(dateToInstance.toString());
            }
        });
        dateToInstance.setDate(now);
        setDateTo(dateToInstance.toString());

    }

    useEffect(()=>{
        pageLoaded();
    },[]);


    const {loading, request} = useHttp();
    const message = useMessage();

    const printSearchContainer = ()=>{

        let showSearchContainer = false;
        if(userType === "director") {
            if(managerInfoState.received)
            {
                showSearchContainer = true;
            }
        }else {
            showSearchContainer = true;
        }

        return (
            <>
                <div style={{display:(showSearchContainer?"block":"none")}}>
                    <div className="row">
                        <div className="col s6">
                            <h6>From</h6>
                            <input type="text" className="datepicker" value={dateFrom}  id="dateFromPicker"/>
                        </div>
                        <div className="col s6">
                            <h6>To</h6>
                            <input type="text" className="datepicker" value={dateTo} id="dateToPicker" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6" style={{display:(userType==="agent"?"none":"block")}}>
                            <h6>Users</h6>
                            <div>

                                <div>
                                    <ul className="collapsible">
                                        <li>
                                            <div className="collapsible-header">Users List  (active: {searchForm.usersList.length} of {users.length} )</div>
                                            <div className="collapsible-body no-padding">
                                                <label>
                                                    <input type="checkbox"
                                                           checked={searchForm.usersList.length === users.length ? "checked" : ""}
                                                           onChange={AllUsersCheckboxClickHandler}
                                                           className="filled-in"/>
                                                    <span>All</span>
                                                </label>
                                                {
                                                    users.length > 0 ? users.map((user, index) => {
                                                        return (
                                                            <div  key={index} className="row" style={{marginBottom: "0px"}}>
                                                                <div className="col s1">
                                                                    <label>
                                                                        <input type="checkbox" data-user={user._id}
                                                                               checked={searchForm.usersList.indexOf(user._id) !== -1 ? "checked" : ""}
                                                                               onChange={newSearchSelectedUserChangeHandler}
                                                                               className="filled-in"/>
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col s5">
                                                                    <label>
                                                                        {user.name}
                                                                    </label>
                                                                </div>
                                                                <div className="col s6">
                                                                    <label>
                                                                        {user.email}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : ""
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="input-field col s3">
                            <div className="row" style={{marginBottom: "0px"}}>
                                <div className="col s12">
                                    <label>
                                        <input type="checkbox"
                                               checked={searchForm.mcList.length === mcSettings.length ? "checked" : ""}
                                               onChange={e => {
                                                   AllMCCheckboxClickHandler(e);
                                               }} className="filled-in"/>
                                        <span>All MC</span>
                                    </label>
                                </div>

                            </div>


                            {
                                mcSettings.length > 0 ? mcSettings.map((mc, index) => {

                                    let mcID = mc._id;
                                    let mcName = mc.name;
                                    if(mc.parentMc){
                                        mcID = mc.parentMc._id;
                                        mcName = mc.parentMc.name;
                                    }

                                    return (
                                        <div  key={index} className="row" style={{marginBottom: "0px"}}>
                                            <div className="col s12">
                                                <label>
                                                    <input type="checkbox" data-mcid={mcID}
                                                           checked={searchForm.mcList.indexOf(mcID) !==-1 ? "checked" : ""}
                                                           onChange={newSearchMcSelectboxChanged}
                                                           className="filled-in"/>
                                                    <span> MC: {mcName}</span>
                                                </label>
                                            </div>

                                        </div>
                                    )
                                }) : ""
                            }
                        </div>
                        <div className="input-field col s3">
                            <div className="row" style={{marginBottom: "0px"}}>
                                <div className="col s12">
                                    <label>
                                        <input type="checkbox"
                                               checked={searchForm.phoneLogs ? "checked" : ""}
                                               onChange={e => {
                                                   setSearchForm({...searchForm,phoneLogs: !searchForm.phoneLogs})
                                               }} className="filled-in"/>
                                        <span>Phone Calls</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: "0px"}}>
                                <div className="col s12">
                                    <label>
                                        <input type="checkbox"
                                               checked={searchForm.emailLogs ? "checked" : ""}
                                               onChange={e => {
                                                   setSearchForm({...searchForm,emailLogs: !searchForm.emailLogs})
                                               }} className="filled-in"/>
                                        <span>Emails</span>
                                    </label>
                                </div>
                            </div>


                        </div>
                    </div>


                    <button className="btn waves-effect waves-light orange"
                            onClick={fetchHistory}
                    >
                        Load
                    </button>

                </div>
            </>
        )
    }

    const fetchHistory = async ()=>{
        let searchData =  {...searchForm};

        let dateToDate = new Date(dateTo);
        dateToDate.setDate(dateToDate.getDate() + 1);

        let dateFromDate = new Date(dateFrom);

        searchData.dateFrom = dateFromDate;
        searchData.dateTo = dateToDate;


        const data = await request("/api/callHistory/getHistory","POST", searchData,true);
        setHistory(data);
    }


    return(
        <>
            {userType === "director"?(
                <div className="row" style={{paddingTop:"10px"}}>
                    <div className="col s6">
                        <select className="browser-default" value={searchForm.managerId} onChange={managerSelectChangeHandler}>
                            <option value="me">Select Manager</option>
                            {managers.map(function(manager, i){
                                return (
                                    <option key={i} value={manager._id}>{manager.name} --- {manager.email}</option>
                                );
                            })}
                        </select>

                    </div>
                </div>

            ):""}

            {printSearchContainer()}



            {(!loading) ?  <CallHistoryList  history={history} />: <Loader />}
        </>
    )
}