import {useState, useCallback} from 'react';
import {useHttp} from "./http.hook";


export const useAnalytic = () => {
    const {loading, request} = useHttp();

    const getLoginHistory =async(query)=>{
        try{
            const data = await request("/api/analytics/loginHistory","POST",query,true);
            return data;
        }catch (e) {

        }
    };

    return  {getLoginHistory}

}
