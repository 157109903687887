import React, {useContext, useEffect, useState} from 'react';
import 'materialize-css';
import {useHttp} from "../hooks/http.hook";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";
import Input, { formatPhoneNumber, formatPhoneNumberIntl, isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input/input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'



export const AuthPage = () =>{
	const auth = useContext(AuthContext);
	const message = useMessage();
	const {loading,error, request,clearError} = useHttp();
	const [tab,setTab] = useState('login');


	useEffect(()=>{
		message(error);
		clearError();
	},[error,message,clearError])

	useEffect(()=>{

		window.M.AutoInit();
		window.M.updateTextFields();
	},[tab])

	const [form, setform] = useState({
		email:"",
		password:"",
		clientType:0
	})

	const [registerForm, setRegisterForm] = useState({
		email:"",
		password:"",
		name:"",
		country:"",
		phone:"",
		repeatPassword:"",
		// newUserType:""
	})

	const [forgotForm, setForgotForm] = useState({
		email:"",
	})

	const changeHandler = event =>
	{
		setform({...form, [event.target.name]:event.target.value})
	}

	const registerChangeHandler = event =>
	{
		setRegisterForm({...registerForm, [event.target.name]:event.target.value})
	}

	const forgotFormChangeHandler = event =>
	{
		setForgotForm({...forgotForm, [event.target.name]:event.target.value})
	}


	const loginHandler =async () =>{
		try {
			const data = await  request('/api/auth/login', 'POST', {...form});
			auth.login(data.accessToken,data.userId,data.userType,data.name,data.email,data.isSubscriptionActive);
		}catch (e) {
		}
	}

	const sendPassHashHandler =async () =>{
		try {
			const data = await  request('/api/auth/sendPasswordHash', 'POST', {...forgotForm});
			message(data.message);

			setTab("login");
		}catch (e) {
		}
	}

	const registerPageHandler =async () =>{
		setTab("register");
	}

	const forgotPasswordPageHandler = async (e)=>{
		e.preventDefault();

		setTab("forgotPassword");
	}

	const backHandler =async () =>{
		setTab("login");
	}

	const phoneChangeHandler = (val)=>{
		// const formattedPhoneNumber = formatPhoneNumber(e.target.value);
		setRegisterForm({...registerForm, phone:val});
	}

	const formatPhoneNumber =(value)=>
	{
		if(!value) return value;

		const phoneNumber = value.replace(/[^\d]/g,"");
		const phoneNumberLength = phoneNumber.lenght;
		if(phoneNumberLength<4) return phoneNumber;

		return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,9)}`;
	}

	const HandleSubmit = async (e) =>
	{
		e.preventDefault();
	}

	const registerHandler =async () =>{
		try {
			let form = {...registerForm};
			if(form.name === "")
			{
				message("Name is Empty");
				return null;
			}

			if(form.email === "")
			{
				message("Email is Empty");
				return null;
			}

			if(form.password === "")
			{
				message("Password is Empty");
				return null;
			}

			if(form.password.length < 6)
			{
				message("Password minimum 6 symbols");
				return null;
			}

			if(form.country === "")
			{
				message("Select your country");
				return null;
			}

			if(!isPossiblePhoneNumber(form.phone) || !isValidPhoneNumber(form.phone))
			{
					message("Wrong phone number");
					return null;
			}
			else
			{
				form.phone = formatPhoneNumberIntl(form.phone);
			}


			// if(registerForm.phone.length !== 12)
			// {
			// 	message("Wrong phone number");
			// 	return null;
			// }
			// else
			// {
			// 	const number = registerForm.phone;
			// 	const code = number.slice(1,4);
			//
			// 	if(code !== "033" && code !== "044" && code !== "055" && code !== "041" && code !== "043" && code !== "077"
			// 		&& code !== "091" && code !== "093" && code !== "094" && code !== "095" && code !== "096" && code !== "097"&& code !== "098"&& code !== "099")
			// 	{
			// 		message("Wrong phone number");
			// 		return null;
			// 	}
			// }

			if(form.repeatPassword === "")
			{
				message("Repeat password is Empty");
				return null;
			}


			if(form.password !== form.repeatPassword)
			{
				message("Passwords don't match");
				return null;
			}


			const data = await  request('/api/auth/register', 'POST', form);
			message(data.message);

			setTab("login");

		}catch (e) {
		}
	}


	const printLoginPage = ()=>
	{
		return (
			<div className="row " style={{display:(tab==="login"?"block":"none")}}>
				<div className="col m6 offset-m3 s12 ">
					<form className="card grey darken-3" onSubmit={HandleSubmit}>
						<div className="card-content white-text login-container">
							<img className="authLogo" src={require('../img/logo192.png')}/>
							<div>
								<div className="input-field">
									<input placeholder="Enter your email"
										   id="email"
										   type="email"
										   name="email"
										   className="yellow-input"
										   value={form.email}
										   onChange={changeHandler}/>
									<label htmlFor="email">Email</label>
								</div>
								<div className="input-field">
									<input
										placeholder="Enter your password"
										id="password"
										type="password"
										name="password"
										className="yellow-input"
										value={form.password}
										onChange={changeHandler}
										disabled={loading}/>
									<label htmlFor="password">Password</label>
								</div>
								<div>
									<a href="#" onClick={forgotPasswordPageHandler}>Forgot Password</a>
								</div>
							</div>
						</div>
						<div className="card-action">
							<button className="btn green darken-1" style={{marginRight:10}}
									onClick={loginHandler} type="submit">Login</button>


							<button className="btn yellow darken-4" style={{marginRight:10}}
									onClick={registerPageHandler} type="submit">Register</button>
						</div>
					</form>
				</div>

			</div>
		)
	}

	const printRegisterPage = ()=>
	{
		return (
			<div className="row" style={{display:(tab==="register"?"block":"none")}}>

				<div className="col m6 offset-m3 s12 ">
					<form className="card grey darken-3" onSubmit={HandleSubmit}>

						<div className="card-content white-text login-container">

							<h4>Manager Registration</h4>
							<div>
								<div className="input-field">
									<input placeholder="Name"
										   id="email"
										   type="text"
										   name="name"
										   className="yellow-input"
										   value={registerForm.name}
										   onChange={registerChangeHandler}/>
									{/*<label htmlFor="email">Name</label>*/}
								</div>
								<div className="input-field">
									<input placeholder="Email"
										   id="email"
										   type="email"
										   name="email"
										   className="yellow-input"
										   value={registerForm.email}
										   onChange={registerChangeHandler}/>
									{/*<label htmlFor="email">Email</label>*/}
								</div>
								<div className="input-field">

									<select
										value={registerForm.country}
										onChange={event => 	setRegisterForm({...registerForm, country:event.target.value})}>
										<option value="">
											Select Country
										</option>
										{getCountries().map((country) => (
											<option key={country} value={country}>
												{en[country]} +{getCountryCallingCode(country)}
											</option>
										))}
									</select>
									<Input
										disabled={registerForm.country === ""}
										placeholder="Enter phone number"
										country={registerForm.country}

										value={registerForm.phone}
										onChange={phoneChangeHandler}
										/>
									{/*<input placeholder="(0xx)xxxxxx"*/}
									{/*	   id="phone"*/}
									{/*	   type="text"*/}
									{/*	   name="phone"*/}
									{/*	   className="yellow-input"*/}
									{/*	   value={registerForm.phone}*/}
									{/*	   onChange={phoneChangeHandler}/>*/}
								</div>
								<div className="input-field">
									<input
										placeholder="Password"
										id="password"
										type="password"
										name="password"
										className="yellow-input"
										value={registerForm.password}
										onChange={registerChangeHandler}
										disabled={loading}/>
									{/*<label htmlFor="password">Password</label>*/}
								</div>
								<div className="input-field">
									<input
										placeholder="Repeat your password"
										id="password"
										type="password"
										name="repeatPassword"
										className="yellow-input"
										value={registerForm.repeatPassword}
										onChange={registerChangeHandler}
										disabled={loading}/>
									{/*<label htmlFor="password">Repeat Password</label>*/}
								</div>
								{/*<div className="input-field">*/}
								{/*	<select*/}
								{/*		name="newUserType"*/}
								{/*		value={registerForm.newUserType}*/}
								{/*		onChange={registerChangeHandler}*/}
								{/*	>*/}
								{/*		<option value="">Choose your option</option>*/}
								{/*		<option value="agent">Agent</option>*/}
								{/*		<option value="manager">Manager</option>*/}
								{/*	</select>*/}
								{/*	<label>New User Type</label>*/}
								{/*</div>*/}
							</div>
						</div>
						<div className="card-action">
							<button className="btn green darken-1" style={{marginRight:10}}
									onClick={registerHandler}>Register Manager</button>

							<button className="btn yellow darken-4" style={{marginRight:10}}
									onClick={backHandler}>Back</button>
						</div>
					</form>
				</div>

			</div>
		)
	}

	const printForgotPasswordPage = ()=>
	{
		return (
			<div className="row ">
				<div className="col m6 offset-m3 s12 ">
					<form className="card grey darken-3" onSubmit={HandleSubmit}>
						<div className="card-content white-text login-container">
							<img className="authLogo" src={require('../img/logo192.png')}/>
							<h5>Forgot Password</h5>
							<br />
							<div>
								<div className="input-field">
									<input placeholder="Enter your email"
										   id="email"
										   type="email"
										   name="email"
										   className="yellow-input"
										   value={forgotForm.email}
										   onChange={forgotFormChangeHandler}/>
									<label htmlFor="email">Email</label>
								</div>

							</div>
						</div>
						<div className="card-action">
							<button className="btn green darken-1" style={{marginRight:10}}
									onClick={sendPassHashHandler} type="submit">Send Email</button>


							<button className="btn yellow darken-4" style={{marginRight:10}}
									onClick={backHandler}>Back</button>
						</div>
					</form>
				</div>

			</div>
		)
	}

	const OpenPage = () =>{

		if(tab === "login" || tab === "register")
		{
			return (
				<>
					{printLoginPage()}
					{printRegisterPage()}
				</>
			)
		}
		else if(tab === "forgotPassword")
		{
			return printForgotPasswordPage();
		}

	}

	return(
		<>
			{OpenPage()}
		</>

	)
}