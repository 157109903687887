import {useHttp} from "./http.hook";

export const usePromoCodes = () => {
    const {loading, request} = useHttp();


    const createRandomPublicPromoCodes = async (form)=>{
        try{
            const data = await request("/api/promocode/createRandomPublic","POST",form,true);
            return data.message;
        }catch (e) {
            return e.message;
        }
    }

    const getFreeRandomPublicPromos = async ()=>{
        try{
            const data = await request("/api/promocode/freeAndPublic","GET",null,true);
            return data;
        }catch (e) {
            return e.message;
        }
    }

    const getUsedRandomPublicPromos =  async ()=>{
        try{
            const data = await request("/api/promocode/usedAndPublic","GET",null,true);
            return data;
        }catch (e) {
            return e.message;
        }
    }


    const getActivatedRandomPublicPromos =  async ()=>{
        try{
            const data = await request("/api/promocode/finishedAndPublic","GET",null,true);
            return data;
        }catch (e) {
            return e.message;
        }
    }


    const updatePromoListFreeStatus = async (list)=>
    {
        try{
            const data = await request("/api/promocode/updatePromoListFreeStatus","POST", {list:list},true);
            return data.message;
        }catch (e) {
            return e.message;
        }
    }










    const getPromoCodes =async()=>{
        try{
            const data = await request("/api/promocode/","GET",null,true);
            return data;
        }catch (e) {
            return e.message;
        }
    }

    const searchPromoCode = async (name) =>{
        try{
            const data = await request("/api/promocode/checkPromoCode","POST", {name:name},true);
            return data;
        }catch (e) {
            return e.message;
        }
    }

    const usePromo = async (promoName) =>{
        try{
            const data = await request("/api/promocode/usePromoCode","POST", {promoName:promoName},true);
            return data;
        }catch (e) {
            return e.message;
        }
    }

    const getPromoTypes =async()=>{
        try{
            const data = await request("/api/promocode/types","GET",null,true);
            console.log(data);
            let keys = Object.keys(data);
            let returnData = [];
            for(let key of keys)
            {
                returnData.push({
                    key:key,
                    value:data[key]
                })
            }
            console.log(returnData);
            return returnData;
        }catch (e) {

        }
    }
    const getPromoEffectTypes =async()=>{
        try{
            const data = await request("/api/promocode/effectTypes","GET",null,true);

            let keys = Object.keys(data);
            let returnData = [];
            for(let key of keys)
            {
                returnData.push({
                    key:key,
                    value:data[key]
                })
            }

            return returnData;
        }catch (e) {

        }
    }

    const createPromoCode = async(form)=>{
        try{
            const data = await request("/api/promocode/create","POST",form,true);
            return data.message;
        }catch (e) {
            return e.message;
        }
    }


    const deletePromoCode = async(id)=>{
        try{
            const data = await request("/api/promocode/delete","POST", {id:id},true);
            return data.message;
        }catch (e) {
            return e.message;
        }
    }

    const assignUserToPromo = async(form)=>{
        try{
            const data = await request("/api/promocode/assignUser","POST",form,true);
            return data.message;
        }catch (e) {
            return e.message;
        }
    }

    const removeUserAssignment = async(form)=>{
        try{
            const data = await request("/api/promocode/removeUserAssignment","POST",form,true);
            return data.message;
        }catch (e) {
            return e.message;
        }
    }

    return  {getPromoCodes,getFreeRandomPublicPromos,updatePromoListFreeStatus,getUsedRandomPublicPromos,getActivatedRandomPublicPromos,      createRandomPublicPromoCodes,searchPromoCode,usePromo,getPromoTypes,getPromoEffectTypes,createPromoCode,deletePromoCode,assignUserToPromo,removeUserAssignment}
}