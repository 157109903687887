import React, {useContext, useEffect, useState} from 'react';
import 'materialize-css';
import {useHttp} from "../hooks/http.hook";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";
import {useCallback} from "react";

export const CreateUserPage = () =>{
    const {userType} = useContext(AuthContext);
    const message = useMessage();
    const {loading,error, request,clearError} = useHttp();
    const [newUserType,setNewUserType] = useState('');
    const [newUserName,setNewUaerName] = useState('');
    const [parentManager,setParentManager] = useState('');
    const [managers, setManagers] = useState([]);
    const [form, setform] = useState({
        email:"",
        password:"",
        newUserType: "",
        name:"",
        phone:"",
        country:"",
        parentUser:null
    })

    const isUserAdmin = userType === "admin";

    const fetchManagers = useCallback(async ()=>{
        try{
            const result = await request("/api/user/manager","GET",null,true);
            setManagers(result);
            var elems = document.getElementById("sel");
            var instances = window.M.FormSelect.init(elems, {});
            window.M.AutoInit();
        }catch (e) {

        }
    },[request]);

    const registerHandler =async e =>{
        e.preventDefault();

        if(form.email === "")
        {
            message("Email is Empty");
            return;
        }
        if(form.password === "")
        {
            message("Password is Empty");
            return
        }
        if(form.password.length < 6)
        {
            message("Password must be minimum 6 symbols");
            return
        }
        if(form.name === "")
        {
            message("Name is Empty");
            return
        }

        try {

            const data = await  request('/api/user/register', 'POST', {...form},true);

            message(data.message);
        }catch (e) {

        }
    }

    const newUserTypeChanged = async e => {
        setNewUserType(e.target.value);

        if(e.target.value === 'agent')
        {
            fetchManagers();
        }

        setform({...form, newUserType:e.target.value})

    }


    const changeHandler = event =>
    {
        setform({...form, [event.target.name]:event.target.value})
    }


    useEffect(()=>{
        window.M.AutoInit();
    },[managers])

    useEffect(()=>{
        message(error);
        clearError();
        window.M.AutoInit();
        window.M.updateTextFields();
    },[error,message,clearError])


    return(
        <div className="row">
            <form className="col offset-m3 m6 s12">
                <h4>{isUserAdmin ? "Add new user":"Add Agent" }</h4>

                <div className="input-field" style={{marginTop:'2rem'}}>
                    <input placeholder="Enter new agent email"
                           id="email"
                           type="email"
                           name="email"
                           value={form.email}
                           onChange={changeHandler}/>
                    <label htmlFor="email">Email</label>
                </div>
                <div className="input-field" style={{marginTop:'2rem'}}>
                    <input placeholder="Enter new agent name"
                           id="name"
                           type="text"
                           name="name"
                           value={form.name}
                           onChange={changeHandler}/>
                    <label htmlFor="name">Name</label>
                </div>
                <div className="input-field">
                    <input
                        placeholder="Enter new agent password"
                        id="password"
                        type="password"
                        name="password"
                        value={form.password}
                        onChange={changeHandler}
                        disabled={loading}/>
                    <label htmlFor="password">Password</label>
                </div>
                { isUserAdmin ?
                    (
                        <>
                            <div className="input-field" style={{marginTop:'2rem'}}>
                                <input placeholder="Enter phone"
                                       id="phone"
                                       type="text"
                                       name="phone"
                                       value={form.phone}
                                       onChange={changeHandler}/>
                                <label htmlFor="name">Phone</label>
                            </div>
                            <div className="input-field">
                                <select
                                    name="userType"
                                    value={newUserType}
                                    onChange={newUserTypeChanged}
                                >
                                    <option value="">Choose your option</option>
                                    <option value="admin">Admin</option>
                                    <option value="director">Director</option>
                                    <option value="manager">Manager</option>
                                    <option value="agent">Agent</option>
                                </select>
                                <label>New User Type</label>
                            </div>
                            <div className="input-field" style={{ display: newUserType==="agent" ? "block" : "none", marginTop:'2rem'}}>
                                <select
                                    name="parentUser"
                                    value={parentManager}
                                    onChange={changeHandler}
                                >
                                    <option value={-1} defaultValue>Choose Parent Manager</option>
                                    {managers.map((manager,index) =>{
                                        return (
                                            <option key={index} value={manager._id}>{manager.email}</option>
                                        )
                                    })}
                                </select>
                                <label>Parent Manager</label>
                            </div>

                        </>

                ):null }
                <div className="card-action">
                    <button className="btn green lighten-1 black-text"
                            onClick={registerHandler}
                            disabled={loading}
                    >Register</button>
                </div>
            </form>

        </div>
    )
}