import React, {useState,useContext} from 'react';
import {useHttp} from "../hooks/http.hook";
import {useCallback, useEffect} from "react";
import {Loader} from "../components/Loader";
import {usePayment} from "../hooks/payment.hook";
import {Link} from "react-router-dom";
import {AuthContext} from "../context/auth.context";


export const PaymentHistoryList = () =>{
    const [paymentHistory, setPaymentHistory] = useState([]);
    const {getPaymentHistory} = usePayment();
    const {userType,userId,userName,userEmail} = useContext(AuthContext);



    const fetchPaymentHistory = async  () =>{
        let history = await getPaymentHistory();
        history = history.reverse();
        setPaymentHistory(history);
    }

    const pageLoaded = async ()=>{
        fetchPaymentHistory();
    }

    useEffect(()=>{
        pageLoaded();
    },[]);

    const printPaymentStatus =  (paymentOrder) =>{

        let visual={
            message:"",
            icon:"",
            color:""
        }

        let confirmedVisual = {
            message:"",
            icon:"check_box",
            color:"green"
        }

        let failVisual = {
            message:"",
            icon:"check_box",
            color:"red"
        }

        let waitVisual = {
            message:"",
            icon:"sync",
            color:"#ff9810"
        }

        if(paymentOrder.isLinkGenerationFailed)
        {
            visual = failVisual;
            visual.message = "redirect fail";
        }
        else
        {
            if(paymentOrder.isOrderConfirmationReceived)
            {
                if(paymentOrder.isPaymentConfirmed)
                {
                    visual = confirmedVisual;
                    visual.message = "";
                }
                else
                {
                    visual = failVisual;
                    visual.message = "Declined";
                }
            }
            else
            {
                if(paymentOrder.type === "Idram")
                {
                    visual = failVisual;
                    visual.message = "Canceled";
                }
                else if(paymentOrder.type === "Arca")
                {
                    visual = waitVisual;
                    visual.message = "Waiting ...";
                }

            }
        }

        return (
            <>
                <i className="material-icons dp48"  style={{color:visual.color,verticalAlign:"middle"}}>{visual.icon}</i>
                {visual.message}
            </>


        )
    }

    return(
        <>
            <div className="section">
                <h5>Payment History</h5>
                <table>
                    <thead>
                    <tr>
                        <th>Payment ID</th>
                        {userType === "manager"?(
                            <th>User</th>
                        ):(
                            <th>Payer</th>
                        )}
                        <th>Date</th>
                        <th>Months</th>
                        <th>Total AMD</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paymentHistory.map((paymentInfo,index) =>{

                        return (
                            <tr key={index}>
                                <td>{paymentInfo.index}</td>
                                <td>
                                    {userType === "manager"?
                                        (paymentInfo.user? (paymentInfo.user.email + " - "+paymentInfo.user.name):"Deleted user")
                                        :
                                        (paymentInfo.payerUser? (paymentInfo.payerUser.email + " - "+paymentInfo.payerUser.name):"")
                                    }

                                </td>

                                <td>{new Date(paymentInfo.dateCreated).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}</td>
                                <td>{paymentInfo.amount}</td>
                                <td>{paymentInfo.priceTotal}</td>
                                <td style={{fontSize:"11px"}}>
                                    {printPaymentStatus(paymentInfo)}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className="divider"></div>
        </>
    );
}